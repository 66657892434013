/**
 * Manage user settings
 *
 * User settings can be stored only temporary, in the DB or in local storage
 * If we want to store the setting in DB we should add name of the setting to
 * dbSettings array or to localSettings array if we want to save it to localStorage
 */

import store from 'config/configureStore';
import { handleError } from './ErrorReducer';
import { getService } from './service';
import { SET_EV_SORT_OPTS } from './EventSortingReducer';
import { SET_UNIT_SORT_OPTS } from './UnitSortingReducer';

const SET_USER_SETTINGS = 'SET_USER_SETTINGS';

const settingsKey = 'CAD-user-v2';

const localSettingsStore = []; // saved in local storage
const dbSettingsStore = [
  // saved in DB
  'themeMode',
  'eventAgencyFilter',
  'eventSortOrder',
  'eventGroupBy',
  'eventSortBy',
  'unitAgencyFilter',
  'unitGroupBy',
  'unitSortBy',
  'unitSortOrder',
  'mapSettings',
  'notifications',
  'showSafetyChecks',
  'showESNsOnMap',
  'showZonesOnMap',
  'mapZoneAgency',
  'eventSort',
  'urnitSort',
  'unitSeq',
];

const defaultUserSettings = {
  loaded: false,
  themeMode: 'day',
  eventAgencyFilter: [],
  eventSortOrder: 'DESC',
  eventGroupBy: 'Status',
  eventSortBy: 'EventID',
  eventShowPinned: false,
  unitAgencyFilter: [],
  unitGroupBy: 'agency',
  unitSortBy: 'unit',
  unitSortOrder: 'DESC',
  unitShowPinned: false,
  unitSeq: [],
  mapSettings: {
    mapZoom: 11,
    mapLat: 30.442053893783818,
    mapLng: -91.1995323879929,
    mapTypeId: 'roadmap', // roadmap, satellite, hybrid, terrain
  },
  showESNsOnMap: false,
  showZonesOnMap: false,
  mapZoneAgency: null,
  notifications: {
    autoHide: true,
    autoHideTime: 5,
  },
  showSafetyChecks: true,
  eventSort: {
    pins: [], // { idx: 0, ptsEventID: 7943 }
    state: {},
    folded: [], // shows which events are unfolded and open tab [{ ptsEventID: 47889, tab: 'units' }]
    filter: '',
    lastTab: 'Units',
  },
  unitSort: {
    folded: [], // shows which units are unfolded and open tab [{ ptsUnitID: 47889, tab: 'units' }]
    filter: '',
  },
};

export const loadUserSettings = () => async (dispatch) => {
  const state = store.store.getState();
  const ptsUserID = state?.user?.userData?.user?.ptsUserID;
  if (!ptsUserID) return dispatch(handleError('Error loading user settings.'));
  // 1. Load local storage settings
  let localUserSettings = {};
  const localSettingsStr = window.localStorage.getItem(settingsKey);
  const localSettings = localSettingsStr ? JSON.parse(localSettingsStr) : {};
  if (localSettingsStr) {
    const userSettings = localSettings[ptsUserID];
    const filteredUserSettings = {};
    // filter user settings only present in localSettingsStore
    localSettingsStore.forEach((key) => {
      if (userSettings[key]) filteredUserSettings[key] = userSettings[key];
    });
    if (userSettings) localUserSettings = filteredUserSettings;
  }
  // 2. Load DB settings
  const dbUserSettings = await loadDBSettings(ptsUserID, dispatch);
  // 3. Concat settings and update redux
  const currentSettings = state.userSettings;
  const userSettings = {
    ...currentSettings,
    ...localUserSettings,
    ...dbUserSettings,
    loaded: true,
  };
  dispatch({ type: SET_USER_SETTINGS, userSettings });
  afterLoadActions(userSettings, dispatch);
};

const afterLoadActions = async (userSettings, dispatch) => {
  const { eventSort, unitSort } = userSettings;
  dispatch({ type: SET_EV_SORT_OPTS, payload: eventSort });
  dispatch({ type: SET_UNIT_SORT_OPTS, payload: unitSort });
};

const loadDBSettings = async (ptsUserID, dispatch) => {
  const service = getService('user-settings');
  let data = {};
  try {
    const unitGroups = await service.find({
      query: {
        ptsUserID: ptsUserID,
        ApplicationName: settingsKey,
        IsDeleted: false,
      },
    });
    if (unitGroups.data.length > 0) data = JSON.parse(unitGroups.data[0].JsonValue);
  } catch (err) {
    dispatch(handleError(err));
  }
  return data;
};

const saveDBSettings = async (ptsUserID, data, dispatch) => {
  const dataStr = JSON.stringify(data);
  const service = getService('user-settings');
  try {
    const exist = await service.find({
      query: {
        ptsUserID: ptsUserID,
        ApplicationName: settingsKey,
        IsDeleted: false,
      },
    });
    if (exist?.data.length === 0) {
      await service.create({
        ptsUserID,
        ApplicationName: settingsKey,
        JsonValue: dataStr,
      });
    } else {
      await service.patch(exist.data[0].ptsUserSettingID, {
        JsonValue: dataStr,
      });
    }
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const saveUserSetting = (name, value) => async (dispatch) => {
  const state = store.store.getState();
  const ptsUserID = state?.user?.userData?.user?.ptsUserID;
  const currentValue = state.userSettings[name];
  if (currentValue === value) return;
  if (!ptsUserID) return dispatch(handleError('Error saving user settings.'));
  const userSettings = { ...state.userSettings, [name]: value };
  // 1. Save in reducer
  dispatch({ type: SET_USER_SETTINGS, userSettings });
  // 2. Save in the DB
  if (dbSettingsStore.indexOf(name) !== -1) {
    const dbUserSettings = {};
    dbSettingsStore.forEach((key) => {
      dbUserSettings[key] = userSettings[key];
    });
    await saveDBSettings(ptsUserID, dbUserSettings, dispatch);
  }
  // 3. Save in local storage
  else if (localSettingsStore.indexOf(name) !== -1) {
    const localSettingsStr = window.localStorage.getItem(settingsKey);
    const localSettings = localSettingsStr ? JSON.parse(localSettingsStr) : {};
    const userLocalSettings = { ...userSettings };
    Object.keys(userSettings).forEach((key) => {
      if (localSettingsStore.indexOf(key) === -1) delete userLocalSettings[key];
    });
    const saveLocalSettings = JSON.stringify({ ...localSettings, [ptsUserID]: userLocalSettings });
    window.localStorage.setItem(settingsKey, saveLocalSettings);
  }
};

export default function reducer(state = defaultUserSettings, action) {
  switch (action.type) {
    case SET_USER_SETTINGS:
      return action.userSettings;
    default:
      return state;
  }
}
