import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import CancelIcon from '@material-ui/icons/Cancel';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { eventStatusChange } from 'reducers/EventsReducer';
import { showSops } from 'reducers/DialogsReducer';
const useStyles = makeStyles((theme) => ({
  menu: {
    '& li': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  menuIcon: {
    margin: '0 10px 0 -10px',
  },
  menuRight: {
    position: 'relative',
    '& > div': {
      width: 10,
      height: 10,
      position: 'absolute',
      top: 0,
      right: 0,
    },
  },
}));

function EventStatusMenu(props) {
  const classes = useStyles();
  const { event, open, onClose, anchorEl, closeMenu, reqDispositions } = props;

  const setEventStatus = (status) => {
    props.eventStatusChange(event.ptsEventID, status);
    closeMenu();
  };

  const closeEvent = () => {
    const reqDispMet =
      reqDispositions.reqDispAgencies.length === 0 && reqDispositions.reqMergedDisp.length === 0;
    if (reqDispMet) {
      //if (event.sops.length) props.showSops({ ptsEventID: event.ptsEventID, fromEventClose: true });
      props.eventStatusChange(event.ptsEventID, 'Closed');
    } else {
      props.dispNotMet();
    }
    closeMenu();
  };

  return (
    <Menu anchorEl={anchorEl} keepMounted open={open} onClose={onClose}>
      <MenuItem onClick={() => setEventStatus('Pending')}>
        <CancelIcon className={classes.menuIcon} /> Cancel
      </MenuItem>
      <MenuItem onClick={() => setEventStatus('Dispatched')}>
        <HeadsetMicIcon className={classes.menuIcon} /> Dispatched
      </MenuItem>
      <MenuItem onClick={() => setEventStatus('Enroute')}>
        <DriveEtaIcon className={classes.menuIcon} /> Enroute
      </MenuItem>
      <MenuItem onClick={() => setEventStatus('Arrived')}>
        <EmojiTransportationIcon className={classes.menuIcon} />
        Arrived
      </MenuItem>
      <MenuItem onClick={() => setEventStatus('Completed')}>
        <AssignmentTurnedInIcon className={classes.menuIcon} /> Completed
      </MenuItem>
      <MenuItem onClick={() => setEventStatus('Controlled')}>
        <ThumbUpIcon className={classes.menuIcon} /> Controlled
      </MenuItem>
      <MenuItem onClick={closeEvent}>
        <RemoveCircleIcon className={classes.menuIcon} /> Closed
      </MenuItem>
    </Menu>
  );
}

export default connect(null, { eventStatusChange, showSops })(EventStatusMenu);
