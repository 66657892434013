import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { displayDateTime } from 'reducers/TimeReducer';
import { handleError } from 'reducers/ErrorReducer';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import fallbackImage from 'assets/images/stock-photos/fallback-image.png';
import Typography from '@material-ui/core/Typography';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Fab from '@material-ui/core/Fab';
import Tooltip from 'components/Tooltip';
import clsx from 'clsx';
import EditIcon from '@material-ui/icons/Edit';
import { getPersonImages } from 'reducers/SearchReducer';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import AttachmentIcon from '@material-ui/icons/Attachment';
import { showPersonPhotoDialog, showPersonPhotoEdit } from 'reducers/DialogsReducer';
import { removePersonImage } from 'reducers/SearchReducer';
import { notify } from 'reducers/NotifierReducer';
import GetAppIcon from '@material-ui/icons/GetApp';
import { downloadPersonPhoto } from 'reducers/FileReducer';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import WebcamPhoto from 'components/WebcamPhoto';
import { getFullPermissions } from 'reducers/PermissionsReducer';

const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  filter: {
    width: '100%',
    marginBottom: '8px',
  },
  card: {
    width: 300,
    height: 450,
    margin: '10px'
  },
  imageList: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly'
  },
  selectedCard: {
    border: `3px solid ${theme.palette.primary.main}`
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    '& button': {
      marginLeft: theme.spacing(1),
    },
    marginBottom: 5
  },
  wrap: {
    padding: theme.spacing(3),
  },
}));

function Photo(props) {
  const classes = useStyles();
  const { ptsPersonID } = props;
  const [rows, setRows] = useState([]);
  const [selected, setSelected] = useState(null);
  const [camOn, setCamOn] = useState(false);
  const dataUpdate = useSelector((state) => state.dataUpdate);
  const perms = getFullPermissions('globals', 'People', 'any');
  const canEditPerson = perms.Edit;

  useEffect(() => {
    search();
  }, []);

  useEffect(() => {
    if (dataUpdate && dataUpdate.type == 'person-image-upload') {
      search();
    }
    // eslint-disable-next-line
  }, [dataUpdate]);

  const search = async () => {
    const allImages = await getPersonImages(ptsPersonID);
    const processed = process(allImages);
    setRows(processed);
  }

  const process = (images) => {
    return images.map(img => {
      img.id = img.ptsImageID;
      return img;
    })
  }

  const handleImageClick = (image) => setSelected(image);

  const attach = () => props.showPersonPhotoDialog(ptsPersonID);

  const handleEdit = () => props.showPersonPhotoEdit(selected);

  const handleDelete = async () => {
    if (!window.confirm('Are you sure you want to remove selected photo?')) return;
    try {
      await removePersonImage(selected.ptsImageID);
      search();
      props.notify('Deleted Successfully', 'success');
    } catch (err) {
      props.handleError(err)
    }
  }

  const handleDownload = async () => {
    try {
      await downloadPersonPhoto(selected.ptsImageID, selected.FileName);
    } catch (err) {
      props.handleError(err);
    }
  }

  const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  const handleCopy = async () => {
    try {
      const blob = b64toBlob(selected.Image, 'image/png');
      await navigator.clipboard.write([new window.ClipboardItem({ 'image/png': blob })]);
      alert('Copied to clipboard')
    } catch (err) {
      console.log(err)
    }
  }

  const renderActions = () => {
    return (
      <div className={classes.actions}>
        <Tooltip title="Camera">
          <span>
            <Fab size="small" color="secondary" onClick={() => setCamOn(true)} disabled={!canEditPerson}>
              <AddAPhotoIcon />
            </Fab>
          </span>
        </Tooltip>
        <Tooltip title="Browse">
          <span>
            <Fab size="small" color="secondary" onClick={attach} disabled={!canEditPerson}>
              <AttachmentIcon />
            </Fab>
          </span>
        </Tooltip>
        {selected && (
          <>
            <Tooltip title="Edit">
              <span>
                <Fab size="small" color="secondary" onClick={handleEdit} disabled={!canEditPerson}>
                  <EditIcon />
                </Fab>
              </span>
            </Tooltip>
            <Tooltip title="Delete">
              <span>
                <Fab size="small" color="secondary" onClick={handleDelete} disabled={!canEditPerson}>
                  <DeleteIcon />
                </Fab>
              </span>
            </Tooltip>
            <Tooltip title="Save">
              <span>
                <Fab size="small" color="secondary" onClick={handleDownload} disabled={!canEditPerson}>
                  <GetAppIcon />
                </Fab>
              </span>
            </Tooltip>
            <Tooltip title="Copy">
              <span>
                <Fab size="small" color="secondary" onClick={handleCopy} disabled={!canEditPerson}>
                  <FileCopyIcon />
                </Fab>
              </span>
            </Tooltip>
          </>
        )}
      </div>
    );
  }

  return (
    <div className={classes.wrap}>
      {renderActions()}
      {camOn && <WebcamPhoto ptsPersonID={ptsPersonID} setCamOn={setCamOn} />}
      <Grid container justify='flex-start'>
        <div className={classes.imageList}>
          {rows.map((person) => (
            <Card
              key={person.ptsImageID}
              className={
                clsx(classes.card,
                  person.ptsImageID === selected?.ptsImageID ?
                    classes.selectedCard : null)
              }
              onClick={() => handleImageClick(person)}
            >
              <CardActionArea>
                <CardMedia
                  component="img"
                  alt="Image"
                  height="300"
                  image={person.Image ? `data:image/jpeg;base64,${person.Image}` : fallbackImage}
                  title={person.FullName}
                />
                <CardContent>
                  <Typography variant="body2" gutterBottom>
                    {person.ImageTypeCode}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {displayDateTime(person.Created)}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Scar, Mark, or Tattoo : {person.IsMark ?
                      <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Is Default : {person.IsDefault ?
                      <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
          {rows.length === 0 && <div className='mt-5'>no images found</div>}
        </div>
      </Grid>
    </div>
  )
}

export default connect(null, {
  showPersonPhotoDialog,
  showPersonPhotoEdit,
  notify,
  handleError,
})(Photo);