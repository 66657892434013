import React, { useState } from 'react';
import { connect } from 'react-redux';
import { do911Test } from '../reducers/E911Reducer';
import csvParser from 'csv-parse';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { notifyPanel } from '../reducers/NotifierReducer';
import { getWorkstationID } from '../reducers/ConfigReducer';
import TextField2 from 'components/TextField2';
import XGrid3 from 'components/XGrid3';

const useStyles = makeStyles((theme) => ({
  pageWrap: {
    padding: theme.spacing(1, 2),
  },
  btnWrap: {
    marginBottom: theme.spacing(1),
    '& button': {
      marginRight: theme.spacing(1),
    },
  },
  csv: {
    width: '100%',
    marginTop: 20,
    background: '#fff',
    '& textarea': {
      fontSize: 12,
      fontFamily: 'monospace',
      lineHeight: 1,
    },
  },
  xgridWrap: {
    marginTop: 20,
    background: '#fff',
    marginBottom: 20,
  },
}));

const notificationOpts = {
  // uid: 'once-please', // you can specify your own uid if required
  title: 'Notification test',
  message: 'New Event',
  position: 'br',
  autoDismiss: 0,
  action: {
    label: 'Create Event',
    callback: () => alert('clicked!'),
  },
};

const cols = [
  'ptsE911ID',
  'StationID',
  'FirstName',
  'LastName',
  'AddressNumber',
  'AddressStreet',
  'ExtraPhone1',
  'Community',
  'XCoordinate',
  'YCoordinate',
  'DataStream',
];

function Page911(props) {
  const classes = useStyles();
  const [csv, setCsv] = useState('');
  const [rows, setRows] = useState(null);
  const [columns, setColumns] = useState(cols);
  const [selection, setSelection] = useState(null);
  const [message, setMessage] = useState('');
  const { notifyPanel } = props;

  const doTest = (no) => async () => {
    await do911Test(getWorkstationID(), no);
  };

  const importCSV = () => {
    csvParser(
      csv,
      {
        comment: '#',
        delimiter: ',',
        skip_lines_with_error: true,
        skip_lines_with_empty_values: true,
        columns: true,
      },
      (err, result) => {
        if (err) {
          console.log(err);
        } else {
          const rows = parseCsv(result);
          setRows(rows);
        }
      }
    );
  };

  const parseCsv = (data) => {
    return data.map((row, id) => {
      const res = {};
      Object.entries(row).forEach(([key, val]) => {
        if (val === 'NULL') {
          res[key] = null;
        } else {
          res[key] = val;
        }
      });
      res.ptsE911ID = parseInt(row.ptsE911ID);
      res.id = row.ROWGUID;
      return res;
    });
  };

  const onSelection = (selection) => {
    setSelection(selection);
  };

  const send911 = async () => {
    const item = rows.find((row) => row.id === selection);
    const workstationID = getWorkstationID();
    const { StationID } = item;
    const match = parseInt(StationID) === parseInt(workstationID) ? 'Match' : 'No Match';
    const message = `StationID: ${StationID} - current: ${workstationID} : ${match}`;
    setMessage(message);
    await do911Test(0, item);
  };

  return (
    <div className={classes.pageWrap}>
      {/* <div className={classes.btnWrap}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => notifyPanel(notificationOpts, 'info')}>
          Notify test
        </Button>
      </div> */}
      <div className={classes.btnWrap}>
        <Button variant="contained" color="primary" onClick={doTest(0)}>
          Test 1
        </Button>
        <Button variant="contained" color="primary" onClick={doTest(1)}>
          Test 2
        </Button>
        <Button variant="contained" color="primary" onClick={doTest(2)}>
          Test 3
        </Button>
        <Button variant="contained" color="primary" onClick={doTest(3)}>
          Test 4
        </Button>
        <Button variant="contained" color="primary" onClick={doTest(4)}>
          Test 5
        </Button>
        <br />
        {!rows && (
          <>
            <TextField2
              className={classes.csv}
              value={csv}
              onChange={(ev) => setCsv(ev.target.value)}
              multiline
              rows="20"
              compact="true"
            />
            <br />
            <Button variant="contained" color="primary" onClick={importCSV} disabled={!csv}>
              Import CSV
            </Button>
          </>
        )}
        {Boolean(rows) && (
          <div>
            <div className={classes.xgridWrap}>
              <XGrid3
                name="e911"
                columns={columns}
                setColumns={setColumns}
                rows={rows}
                setSelection={onSelection}
              />
            </div>
            <Button variant="contained" color="primary" onClick={send911} disabled={!selection}>
              Send 911
            </Button>
            <br />
            <br />
            {message}
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
  config: state.config,
});

export default connect(mapStateToProps, { notifyPanel })(Page911);
