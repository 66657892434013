import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { displayDateTime } from 'reducers/TimeReducer';
import { handleError } from 'reducers/ErrorReducer';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from 'components/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { showDisplayDataWindow } from 'reducers/DialogsReducer';
import { searchPhoneNumbers } from 'reducers/SearchReducer';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import XGrid3 from 'components/XGrid3';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import { Redirect } from 'react-router-dom';
import { showEditPerson, showEditPlace } from 'reducers/DialogsReducer';
import { notify } from 'reducers/NotifierReducer';
import LocalPrintshopRoundedIcon from '@material-ui/icons/LocalPrintshopRounded';
import QuickSearch from '../components/QuickSearch';
import { Alert } from '@material-ui/lab';
import RecordLimitAlert from 'Search/components/RecordLimitAlert';
const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filter: {
    width: '100%',
    marginBottom: '8px',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  panel: {
    '& .MuiDataGridPanelFooter-root': {
      display: 'none',
    },
  },
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const defaultColumns = [
  'PartyType',
  'ParentName',
  'ParentID',
  'ContactMethod',
  'MethodType',
  'PhoneNumber',
  {
    field: 'IsDefault',
    renderCell: bool,
    format: 'bool',
  },
  {
    field: 'Created',
    valueFormatter: (params) => displayDateTime(params.value),
  },
  'CreatedBy',
  {
    field: 'Updated',
    valueFormatter: (params) => displayDateTime(params.value),
  },
  'UpdatedBy',
  {
    field: 'IsDeleted',
    renderCell: bool,
    format: 'bool',
  },
  {
    field: 'Deleted',
    valueFormatter: (params) => displayDateTime(params.value),
  },
  'DeletedBy',
  'DeletedIP',
  {
    field: 'ParentIsDeleted',
    renderCell: bool,
    format: 'bool',
  },
  {
    field: 'ParentDeleted',
    valueFormatter: (params) => displayDateTime(params.value),
  },
  'ParentDeletedBy',
  'ParentDeletedIP',
];

const searchCols = ['PhoneNumber', 'ParentName', 'MethodType'];
let lastSearchTerm = '';

function PhoneNumber(props) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [rawRows, setRawRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [loaded, setLoaded] = useState(true);
  const [phoneNumberColumns, setPhoneNumberColumns] = useState(defaultColumns);
  const [printFlag, setPrintFlag] = useState(false);
  const personPermissions = getFullPermissions('globals', 'People', 'any');
  const placePermissions = getFullPermissions('globals', 'Places', 'any');
  const Read = personPermissions.Read || placePermissions.Read;
  const [rowCount, setRowCount] = useState(0);
  const limit = 1000;
  const [page, setPage] = useState(0);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (rows.length !== 0 && rows.length === rowCount) setShowAlert(false);
    else if (rows.length >= limit) setShowAlert(true);
  }, [rows, rowCount]);

  const search = async (text = lastSearchTerm, offset = 0, mergeRows = false) => {
    lastSearchTerm = text;
    if (text.length < 3) return;
    setLoaded(false);
    searchPhoneNumbers(text, offset, limit)
      .then((result) => {
        const nextStartingIndex = rows.length;
        //adding total number of rows
        result.length && setRowCount(result[0].Count);
        //TRUE means, fetching next 1000 rows
        //so merging new rows with existing
        if (mergeRows)
          setRows((prevState) => [...prevState, ...processData(result, nextStartingIndex)]);
        else {
          setRows(processData(result));
          setPage(0); //resetting page
        }
      })
      .catch(props.handleError)
      .finally(() => setLoaded(true));
  };

  const processData = (data, nextStartingIndex = 0) => {
    return data.map((row, idx) => {
      return {
        ...row,
        id: idx + nextStartingIndex,
      };
    });
  };

  const viewDetails = () => {
    let item = rows.find((row) => row.id === selection);
    if (!item) return;
    props.showDisplayDataWindow({
      data: filterData(item),
      title: `Phone number Details - ${item.ParentName}`,
    });
  };

  const filterData = (rawData) => {
    const {
      PartyType,
      ParentName,
      ParentID,
      ContactMethod,
      MethodType,
      PhoneNumber,
      IsDefault,
      Notes,
      Created,
      CreatedBy,
      Updated,
      UpdatedBy,
    } = rawData;
    return {
      PartyType,
      ParentName,
      ParentID,
      ContactMethod,
      MethodType,
      PhoneNumber,
      IsDefault,
      Notes,
      Created,
      CreatedBy,
      Updated,
      UpdatedBy,
    };
  };

  const showEdit = () => {
    if (selection === null) return false;
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    const { ptsParentID, PartyType } = item;
    if (PartyType === 'Place') {
      if (placePermissions.Edit && ptsParentID) return true;
    } else {
      if (personPermissions.Edit && ptsParentID) return true;
    }
    return false;
  };

  const edit = () => {
    const item = rows.find((row) => row.id === selection);
    const { ptsParentID, PartyType } = item;
    if (PartyType === 'Place') {
      if (placePermissions.Edit) {
        props.showEditPlace(ptsParentID);
      } else {
        props.notify("You not' have permissions to view this record");
      }
    } else {
      if (personPermissions.Edit) {
        props.showEditPerson(ptsParentID);
      } else {
        props.notify("You not' have permissions to view this record");
      }
    }
  };

  const handlePrintClick = () => {
    setPrintFlag(true);
    setTimeout(() => setPrintFlag(false), 1000);
  };

  const onPageChange = () => {
    const offset = (page + 1) * limit;
    const mergingRows = true;
    search(lastSearchTerm, offset, mergingRows);
    setPage(page + 1);
  };

  if (!Read) return <Redirect to="/" />;

  return (
    <div>
      <h5>Phone Numbers</h5>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          <QuickSearch
            search={search}
            rows={rawRows}
            setRows={setRows}
            cols={searchCols}
            loaded={loaded}
          />
        </div>
        <div className={classes.actions}>
          <Tooltip title="Print list">
            <Fab size="small" color="secondary" onClick={handlePrintClick}>
              <LocalPrintshopRoundedIcon />
            </Fab>
          </Tooltip>
          {selection !== null && (
            <Tooltip title="View details">
              <Fab className="ml-2" size="small" color="secondary" onClick={viewDetails}>
                <InfoIcon />
              </Fab>
            </Tooltip>
          )}
          {showEdit() && (
            <Tooltip title="Edit person">
              <Fab className="ml-2" size="small" color="secondary" onClick={edit}>
                <EditIcon />
              </Fab>
            </Tooltip>
          )}
        </div>
      </div>
      {showAlert && (
        <RecordLimitAlert
          handleNextPage={onPageChange}
          rowCount={rowCount}
          currentRows={rows.length}
        />
      )}
      <XGrid3
        name="Phone Numbers"
        columns={phoneNumberColumns}
        setColumns={setPhoneNumberColumns}
        rows={rows}
        loading={!loaded}
        setSelection={setSelection}
        printFlag={printFlag}
        viewDetails={viewDetails}
        refresh={search}
      />
    </div>
  );
}

export default connect(null, {
  handleError,
  showDisplayDataWindow,
  showEditPerson,
  showEditPlace,
  notify,
})(PhoneNumber);
