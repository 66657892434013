import React from 'react';
import { connect } from 'react-redux';
import PagingDialog from './PagingDialog';
import AddOutserviceUnitDialog from './AddOutserviceUnitDialog';
import DialogUnitStatus from './DialogUnitStatus';
import AddEvent from './AddEvent';
import EditEvent from './EditEvent';
import AddSop from './AddSOP';
import DispatchRecommendation from './DispatchRecommendation';
import UnitProps from './UnitProps';
import UnitZone from './UnitZone';
import Settings from './Settings';
import DisplayData from './DisplayData';
import SOPs from './SOPs';
import AddBulletin from './AddBulletin';
import EditBulletin from './EditBulletin';
import Address from './Address';
import BulletinDetails from './BulletinDetails';
import ReportsDialog from './ReportsDialog';
import RotationsDialog from './RotationsDialog';
import AddVehicleDialog from './AddVehicleDialog';
import EditVehicleDialog from './EditVehicleDialog';
import TowingDialog from './TowingDialog';
import VehicleAddEditRegDialog from './EditVehicleDialog/VehicleAddEditRegDialog';
import AboutDialog from './AboutDialog';
import AddUnitDialog from './AddUnitDialog';
import EditUnitDialog from './EditUnitDialog';
import DictionaryDialog from './DictionaryDialog';
import TestingDialog from './TestingDialog';
import AddAttachment from './AddAttachment';
import EditAttachment from './EditAttachment';
import EditCredentialDialog from './EditCredentialDialog';
import PlaceEdit from './PlaceEdit';
import AddPersonDialog from './AddPersonDialog';
import EditNameDialog from './EditNameDialog';
import EditPersonDialog from './EditPersonDialog';
import ContactMethodDialog from './ContactMethodDialog';
import PeopleCredentialsDialog from './PeopleCredentialsDialog';
import PeopleEmploymentDialog from './PeopleEmploymentDialog.js';
import PlaceEmployeeDialog from './PlaceEmployeeDialog';
import WarrantDialog from './WarrantDialog';
import AddDnaSampleDialog from './EditPersonDialog/AddDnaSampleDialog';
import AddFeatureDialog from './EditPersonDialog/AddFeatureDialog';
import AddPersonPhotoAttachment from './AddPersonPhotoAttachment';
import EditPersonPhotoDialog from './EditPersonDialog/EditPersonPhotoDialog';
import PersonNameDialog from './EditPersonDialog/PersonNameDialog';
import RelatedRecordDetails from './EditPersonDialog/RecordDetailsDialog';
import EditPlaceAddressDialog from './PlaceEdit/EditPlaceAddressDialog';
import AddEditUnitResourceDialog from './EditUnitDialog/AddEditUnitResourceDialog';
import EditZones from './EditZones';
//import AddStandByOutserviceUnitDialog from './AddStandByOutserviceUnitDialog';
import AddMultiSelectableUnitDialog from './AddMultiSelectableUnitDialog';
import DialogMultipleUnitStatusChange from './DialogMultipleUnitStatusChange';
import EditRotationHistory from './EditRotationHistoy';

function Dialogs(props) {
  const { isAuthenticated, dialogs } = props;
  const {
    addPage,
    addOutserviceUnit,
    addEvent,
    editEvent,
    editSop,
    dispRecommendation,
    unitProps,
    addNote,
    newUnitStatus,
    unitZone,
    settings,
    displayData,
    sops,
    addBulletin,
    editBulletin,
    address,
    bulletinDetails,
    report,
    rotations,
    addVehicle,
    editVehicle,
    vehicleEditReg,
    towing,
    about,
    addUnit,
    editUnit,
    dictionary,
    testing,
    addAttachment,
    editAttachment,
    editCredential,
    editPlace,
    editName,
    editContactMethod,
    editPeopleCredentials,
    editPeopleEmployment,
    editPlaceEmployment,
    addPerson,
    editPerson,
    warrants,
    personDnaSample,
    personFeature,
    personPhotoAttachment,
    editPersonPhoto,
    personName,
    personRelatedRecords,
    editPlaceAddress,
    addEditUnitResource,
    editZone,
    //addStandByOutService,
    addMultiSelectableUnit,
    multipleUnitStatusChange,
    editRotation,
  } = dialogs;

  return (
    <>
      {isAuthenticated && (
        <>
          {Boolean(addPage) && <PagingDialog />}
          {addOutserviceUnit && <AddOutserviceUnitDialog />}
          {!!addEvent && <AddEvent data={addEvent} />}
          {Boolean(editEvent) && <EditEvent ptsEventID={editEvent} />}
          {Boolean(editSop) && <AddSop /* ptsSOPID={editSop} */ data={editSop} />}
          {dispRecommendation !== null && (
            <DispatchRecommendation ptsEventID={dispRecommendation} />
          )}
          {unitProps !== null && <UnitProps ptsUnitID={unitProps} />}
          {newUnitStatus !== null && <DialogUnitStatus data={newUnitStatus} />}
          {unitZone !== null && <UnitZone ptsUnitID={unitZone} />}
          {settings !== null && <Settings />}
          {displayData !== null && (
            <DisplayData
              data={displayData.data}
              title={displayData.title}
              ptsAlertID={displayData.ptsAlertID}
            />
          )}
          {sops !== null && (
            <SOPs
              ptsEventID={typeof sops === 'object' ? sops.ptsEventID : sops}
              fromEventClose={typeof sops === 'object' ? sops.fromEventClose : false}
            />
          )}
          {addBulletin !== null && <AddBulletin data={addBulletin} />}
          {editBulletin !== null && <EditBulletin ptsAlertID={editBulletin} />}
          {editRotation !== null && <EditRotationHistory data={editRotation} />}
          {address !== null && (
            <Address
              ptsAddressID={address.ptsAddressID}
              tab={address.tab}
              eventID={address.EventID}
              copyAddress={address.copyAddress}
            />
          )}
          {bulletinDetails !== null && <BulletinDetails data={bulletinDetails} />}
          {report !== null && <ReportsDialog report={report} />}
          {rotations !== null && <RotationsDialog data={rotations} />}
          {addVehicle !== null && <AddVehicleDialog data={addVehicle} />}
          {editVehicle !== null && <EditVehicleDialog vehicleData={editVehicle} />}
          {vehicleEditReg !== null && <VehicleAddEditRegDialog vehicleData={vehicleEditReg} />}
          {towing !== null && <TowingDialog data={towing} />}
          {about !== null && <AboutDialog />}
          {addUnit !== null && <AddUnitDialog />}
          {editUnit !== null && <EditUnitDialog ptsUnitID={editUnit} />}
          {dictionary !== null && <DictionaryDialog data={dictionary} />}
          {testing !== null && <TestingDialog />}
          {addAttachment !== null && <AddAttachment data={addAttachment} />}
          {editAttachment !== null && <EditAttachment data={editAttachment} />}
          {editCredential !== null && <EditCredentialDialog data={editCredential} />}
          {editPlace !== null && (
            <PlaceEdit
              ptsPlaceID={typeof editPlace === 'object' ? editPlace.ptsPlaceID : editPlace}
              ptsAddressID={typeof editPlace === 'object' ? editPlace.ptsAddressID : null}
            />
          )}
          {addPerson !== null && <AddPersonDialog data={addPerson} />}
          {editPerson !== null && <EditPersonDialog person={editPerson} />}
          {editName !== null && <EditNameDialog ptsNameID={editName} />}
          {editPlaceEmployment !== null && <PlaceEmployeeDialog data={editPlaceEmployment} />}
          {editContactMethod !== null && <ContactMethodDialog data={editContactMethod} />}
          {editPeopleCredentials !== null && (
            <PeopleCredentialsDialog data={editPeopleCredentials} />
          )}
          {editPeopleEmployment !== null && (
            <PeopleEmploymentDialog ptsPersonID={editPeopleEmployment} />
          )}
          {warrants !== null && <WarrantDialog data={warrants} />}
          {personDnaSample != null && <AddDnaSampleDialog ptsPersonID={personDnaSample} />}
          {personFeature != null && <AddFeatureDialog ptsPersonID={personFeature} />}
          {personPhotoAttachment !== null && (
            <AddPersonPhotoAttachment ptsPersonID={personPhotoAttachment} />
          )}
          {editPersonPhoto !== null && <EditPersonPhotoDialog data={editPersonPhoto} />}
          {personName !== null && <PersonNameDialog data={personName} />}
          {personRelatedRecords !== null && <RelatedRecordDetails data={personRelatedRecords} />}
          {editPlaceAddress !== null && <EditPlaceAddressDialog ptsAddressID={editPlaceAddress} />}
          {addEditUnitResource !== null && <AddEditUnitResourceDialog data={addEditUnitResource} />}
          {editZone !== null && <EditZones data={editZone} />}
          {addMultiSelectableUnit !== null && (
            <AddMultiSelectableUnitDialog data={addMultiSelectableUnit} />
          )}
          {multipleUnitStatusChange !== null && (
            <DialogMultipleUnitStatusChange data={multipleUnitStatusChange} />
          )}
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.user.isAuthenticated,
    dialogs: state.dialogs,
  };
};

export default connect(mapStateToProps)(Dialogs);
