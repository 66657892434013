import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import IconButton from '@material-ui/core/IconButton';
import { getService } from 'reducers/service';
import { notify } from 'reducers/NotifierReducer';
import { handleError } from 'reducers/ErrorReducer';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { dateTimePicker, formatSaveDate, getDateTimeFormat } from 'reducers/TimeReducer';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';

const useStyles = makeStyles((theme) => ({
  actions: {
    padding: '5px 15px',
    textAlign: 'right',
    borderBottom: `1px solid ${theme.card.hr}`,
    '& label': {
      marginBottom: 0,
    },
  },
  wrapper: {
    margin: 20,
  },
  datePickers: {
    padding: '16px 16px 8px',
    '& > div': {
      margin: 4,
      width: 250,
    },
  },
}));

function TimesTab(props) {
  const classes = useStyles();
  const { event, disableEdit } = props;
  const [Received, setReceived] = useState(null);
  const [Queued, setQueued] = useState(null);
  const [Dispatched, setDispatched] = useState(null);
  const [Enroute, setEnroute] = useState(null);
  const [Arrived, setArrived] = useState(null);
  const [UnderCont, setUnderCont] = useState(null);
  const [Completed, setCompleted] = useState(null);
  const [Cancelled, setCancelled] = useState(null);
  const dateFormat = getDateTimeFormat();
  const permissions = getFullPermissions('cad', 'Events', 'any');
  const disabled = !permissions.Edit;

  useEffect(() => {
    if (!event) return;
    const {
      ReceiveDate,
      QueuedDate,
      DispatchedDate,
      EnrouteDate,
      ArrivedDate,
      UnderContDate,
      CompletedDate,
      CancelledDate,
    } = event;

    setReceived(dateTimePicker(ReceiveDate));
    setQueued(dateTimePicker(QueuedDate));
    setDispatched(dateTimePicker(DispatchedDate));
    setEnroute(dateTimePicker(EnrouteDate));
    setArrived(dateTimePicker(ArrivedDate));
    setUnderCont(dateTimePicker(UnderContDate));
    setCompleted(dateTimePicker(CompletedDate));
    setCancelled(dateTimePicker(CancelledDate));
    // eslint-disable-next-line
  }, [event]);

  const handleSave = () => {
    const data = {
      Received: formatSaveDate(Received),
      Queued: formatSaveDate(Queued),
      Dispatched: formatSaveDate(Dispatched),
      Enroute: formatSaveDate(Enroute),
      Arrived: formatSaveDate(Arrived),
      Completed: formatSaveDate(Completed),
      UnderCont: formatSaveDate(UnderCont),
      Cancelled: formatSaveDate(Cancelled),
    };
    const service = getService('cad-event-times');
    service
      .patch(event.ptsEventID, data)
      .then((res) => {
        props.notify('Event Times Updated', 'success');
        props.notifyDataUpdate({ type: 'event', data: event.ptsEventID });
      })
      .catch((err) => props.handleError(err, 'Cannot Update Event Times'));
  };

  const renderActions = () => {
    return (
      <div className={classes.actions}>
        <IconButton
          color="primary"
          size="small"
          component="span"
          onClick={handleSave}
          disabled={disabled}>
          <SaveIcon />
        </IconButton>
      </div>
    );
  };

  const renderReceived = () => {
    return (
      <KeyboardDateTimePicker
        autoOk
        className={classes.timePicker}
        value={Received}
        onChange={setReceived}
        inputVariant="outlined"
        size="small"
        label="Received"
        format={dateFormat}
        disabled={disabled}
      />
    );
  };

  const renderQueued = () => {
    return (
      <KeyboardDateTimePicker
        autoOk
        className={classes.timePicker}
        value={Queued}
        onChange={setQueued}
        inputVariant="outlined"
        size="small"
        label="Queued"
        format={dateFormat}
        disabled={disabled}
      />
    );
  };

  const renderDispatched = () => {
    return (
      <KeyboardDateTimePicker
        autoOk
        className={classes.timePicker}
        value={Dispatched}
        onChange={setDispatched}
        inputVariant="outlined"
        size="small"
        label="Dispatched"
        format={dateFormat}
        disabled={disabled}
      />
    );
  };

  const renderEnroute = () => {
    return (
      <KeyboardDateTimePicker
        autoOk
        className={classes.timePicker}
        value={Enroute}
        onChange={setEnroute}
        inputVariant="outlined"
        size="small"
        label="Enroute"
        format={dateFormat}
        disabled={disabled}
      />
    );
  };

  const renderArrived = () => {
    return (
      <KeyboardDateTimePicker
        autoOk
        className={classes.timePicker}
        value={Arrived}
        onChange={setArrived}
        inputVariant="outlined"
        size="small"
        label="Arrived"
        format={dateFormat}
        disabled={disabled}
      />
    );
  };

  const renderUnderControl = () => {
    return (
      <KeyboardDateTimePicker
        autoOk
        className={classes.timePicker}
        value={UnderCont}
        onChange={setUnderCont}
        inputVariant="outlined"
        size="small"
        label="Under Control"
        format={dateFormat}
        disabled={disabled}
      />
    );
  };

  const renderCancelled = () => {
    return (
      <KeyboardDateTimePicker
        autoOk
        className={classes.timePicker}
        value={Cancelled}
        onChange={setCancelled}
        inputVariant="outlined"
        size="small"
        label="Canceled"
        format={dateFormat}
        disabled={disabled}
      />
    );
  };

  const renderCompleted = () => {
    return (
      <KeyboardDateTimePicker
        autoOk
        className={classes.timePicker}
        value={Completed}
        onChange={setCompleted}
        inputVariant="outlined"
        size="small"
        label="Completed"
        format={dateFormat}
        disabled={disabled}
      />
    );
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {!disableEdit && renderActions()}
      <div className={classes.datePickers}>
        {renderReceived()}
        {renderQueued()}
        {renderDispatched()}
        {renderEnroute()}
        {renderArrived()}
        {renderUnderControl()}
        {renderCompleted()}
        {renderCancelled()}
        <div />
        <div />
      </div>
    </MuiPickersUtilsProvider>
  );
}

export default connect(null, {
  notify,
  handleError,
  notifyDataUpdate,
})(TimesTab);
