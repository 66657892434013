import { getService } from './service';
import store from '../config/configureStore';

export const getReport = (reportId, data) => {
  const service = getService('ssrs');
  service.timeout = 90000;
  return service.get(reportId, {
    query: { data },
  });
};

export const isFireRMSActiveEvent = (data) => {
  const state = store.store.getState();
  const { AgencyID } = data;
  const Agencies = state?.dictionary?.Agencies;
  if (!AgencyID || !Agencies || !Agencies.length) return false;
  const agency = Agencies.find((a) =>
    AgencyID.split(', ').some((agency) => a.AgencyID === agency && a.FireHouseEnabled)
  );
  if (!agency) return false;
  return agency.FireHouseEnabled;
};

export const isFireRMSActiveAdvancedEvent = (data) => {
  const state = store.store.getState();
  const { AgencyID } = data;
  const Agencies = state?.dictionary?.Agencies;
  if (!Agencies || !Agencies.length) return false;
  const agency = Agencies.find((a) => a.AgencyID === AgencyID);
  if (!agency) return false;
  return agency.FireHouseEnabled;
};

export const sendRipAndRun = (ptsEventID) => {
  const service = getService('rip-and-run');
  service.timeout = 90000;
  return service.create({
    ptsEventID,
  });
};
