import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import CustomMsgBox from 'components/CustomMsgBox';
import EventForm from '../AddEvent/EventForm';
// import CallerForm from '../AddEvent/CallerForm';
// import CallersCard from './CallersCard';
import LocationForm from '../AddEvent/LocationForm';
import LocationsCard from './LocationsCard2';
// import NotesForm from '../AddEvent/NotesForm';
// import NotesCard from './NotesCard';
import EventCard from './EventCard';
import {
  getEventData,
  // saveEventNote,
  // saveEventCaller,
  saveLocation,
  saveEventEvent,
  pushEvToLegacyRMS,
  // removeEventCaller,
  // removeEventLocation,
  // removeEventNote,
} from 'reducers/EventsReducer';
import {
  locationEmptyData,
  // callerEmptyData,
  eventEmptyData,
} from '../AddEvent/eventEmptyData';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { notify, showCustomMsgBox, hideCustomMsgBox } from 'reducers/NotifierReducer';
import { handleError } from 'reducers/ErrorReducer';
import { closeEditEvent } from 'reducers/DialogsReducer';
import Dialog from 'components/Dialog';
import { updateAddressHistory, getAddressHistory } from 'reducers/AddressReducer';
import EditEventTabs from './EditEventTabs';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import Tooltip from 'components/Tooltip';
import { IconButton } from '@material-ui/core';
import { getFullPermissions, getAccessPermission } from 'reducers/PermissionsReducer';
import { cleanEventSort } from 'reducers/EventSortingReducer';

const useStyles = makeStyles((theme) => ({
  contentWrap: {
    minWidth: 800,
    minHeight: 600,
  },
  evNotFound: {
    color: theme.palette.error.main,
  },
  root: {
    marginTop: 1,
  },
  seconderyBar: {
    display: 'flex',
    width: '100%',
    justifyContent: 'spaces-between',
    alignItems: 'center',
    '& > div': {
      '&:first-child': {
        width: 'calc(50% - 45px)',
      },
      '&:nth-child(2)': {
        textAlign: 'center',
        width: 90,
        flex: '1 0 90px',
      },
      '&:last-child': {
        textAlign: 'right',
        width: 'calc(50% - 45px)',
      },
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  leftActions: {
    '& button svg': {
      marginRight: 0,
    },
  },
}));

const showPushToLegacy = process.env.REACT_APP_PUSH_TO_LEGACY === 'true';

function EditEvent(props) {
  const classes = useStyles();
  const [notFound, setNotFound] = useState(false);
  const [rawData, setRawData] = useState(null);
  const [editedEventData, setEditedEventData] = useState(null);
  // const [callersData, setCallersData] = useState([]);
  // const [editedCallerData, setEditedCallerData] = useState(false);
  const [locationsData, setLocationsData] = useState([]);
  const [editedLocationData, setEditedLocationData] = useState(false);
  const [pushToLegacyActive, setPushToLegacyActive] = useState(true);
  // const [notesData, setNotesData] = useState([]);
  // const [editedNotesData, setEditedNotesData] = useState('');
  const { dictionary, websocket, ptsEventID } = props;
  const newEventData = useRef({ ...eventEmptyData });
  const newLocationData = useRef({ ...locationEmptyData });
  // const newCallersData = useRef({ ...callerEmptyData });
  const permissions = getFullPermissions('cad', 'Events', 'any');
  const editClosedEvents = getAccessPermission('cad', 'Edit Closed Events', 'any');
  let status = rawData && rawData.Event && rawData.Event.Status ? rawData.Event.Status : '';
  const disableEdit = !(
    permissions.Edit &&
    (status.toLowerCase() !== 'closed' || editClosedEvents)
  );

  useEffect(() => {
    return () => {
      props.hideCustomMsgBox();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!websocket.websocket) return;
    updateEventData();
    // eslint-disable-next-line
  }, [websocket]);

  useEffect(() => {
    if (!rawData || dictionary.notLoaded) return;
    if (!disableEdit) setEditedEventData(rawData.Event);
    processData(rawData);
  }, [rawData, dictionary]);

  const processData = (data) => {
    if (!data) return;
    // setNotesData(data.Notes);
    setLocationsData(data.Locations);
    // setCallersData(data.Callers);
    // if (data.Locations.length === 1) setEditedLocationData(data.Locations[0]);
    // if (data.Callers.length === 1) setEditedCallerData(data.Callers[0]);
    checkAddrHistoryChange(data);
  };

  const checkAddrHistoryChange = async (data) => {
    const { AddressHistory, ptsEventID } = data.Event;
    let newAddressHistory = AddressHistory;
    const primaryLocation = data.Locations.find((loc) => loc.IsPrimary);
    if (primaryLocation) {
      const { ptsAddressID } = primaryLocation;
      try {
        const result = await getAddressHistory(ptsAddressID);
        newAddressHistory = result.filter((hist) => hist.ptsEventID !== ptsEventID).length;
      } catch (err) {
        props.handleError(err, 'Error updating address history.');
      }
    } else {
      newAddressHistory = 0;
    }
    if (AddressHistory !== newAddressHistory) {
      updateAddressHistory(ptsEventID, newAddressHistory);
    }
  };

  // Locations
  const showAddNewLocation = () => {
    if (editedLocationData) return;
    setEditedLocationData({ ...locationEmptyData });
  };

  const showEditLocation = (ptsLocationAddressID) => {
    if (editedLocationData) return;
    const locationData = locationsData.find(
      (location) => location.ptsLocationAddressID === ptsLocationAddressID
    );
    setEditedLocationData({ ...locationData });
  };

  const clearEditedLocation = () => {
    setEditedLocationData(false);
  };

  const saveLoc = async () => {
    props.showSpinner();
    try {
      await saveLocation(newLocationData.current, ptsEventID, dictionary);
      updateEventData();
      setEditedLocationData(null);
      props.hideCustomMsgBox();
    } catch (err) {
      props.handleError(err);
    }
    props.hideSpinner();
  };

  const removeLocation = (ptsLocationAddressID) => {
    if (window.confirm('Are you sure you want to delete this location?')) {
      props.showSpinner();
      removeEventLocation(ptsLocationAddressID)
        .then(() => updateEventData())
        .catch((error) => props.handleError(error, 'Error, Location not removed'))
        .finally(() => props.hideSpinner());
    }
  };

  const updateNewLocation = (data) => {
    newLocationData.current = data;
  };

  const addPlace = (place) => {
    setEditedLocationData({
      ...place,
      IsPrimary: newLocationData.current.IsPrimary,
    });
  };

  const clearPlace = () => {
    setEditedLocationData({
      ...locationEmptyData,
      ptsLocationAddressID: newLocationData.current.ptsLocationAddressID,
      IsPrimary: newLocationData.current.IsPrimary,
    });
  };

  // Callers
  // const showAddNewCaller = () => {
  //   if (editedCallerData) return;
  //   setEditedCallerData({ ...callerEmptyData });
  // };

  // const showEditCaller = (ptsCallerID) => {
  //   if (editedCallerData) return;
  //   const callerData = callersData.find((caller) => caller.ptsCallerID === ptsCallerID);
  //   setEditedCallerData({ ...callerData });
  // };

  // const clearEditedCaller = () => {
  //   setEditedCallerData(false);
  // };

  // const saveCaller = () => {
  //   if (!newCallersData.current) return;
  //   props.showSpinner();
  //   saveEventCaller(newCallersData.current, ptsEventID)
  //     .then(() => {
  //       updateEventData();
  //       setEditedCallerData(false);
  //     })
  //     .catch((error) => props.handleError(error, 'Error, caller not saved.'))
  //     .finally(() => props.hideSpinner());
  // };

  // const removeCaller = (ptsCallerID) => {
  //   if (window.confirm('Are you sure you want to delete this caller?')) {
  //     props.showSpinner();
  //     removeEventCaller(ptsCallerID)
  //       .then(() => updateEventData())
  //       .catch((error) => props.handleError(error, 'Error, caller not removed.'))
  //       .finally(() => props.hideSpinner());
  //   }
  // };

  // const updateNewCaller = (data) => {
  //   newCallersData.current = data;
  // };

  // Event
  const setEventValue = (name, value) => {
    const newEvent = { ...editedEventData };
    newEvent[name] = value;
    setEditedEventData(newEvent);
  };

  const clearEditedEvent = () => {
    setEditedEventData(false);
  };

  const showEditEvent = () => {
    if (editedEventData) return;
    setEditedEventData(rawData.Event);
  };

  const saveEvent = () => {
    if (!newEventData.current) return;
    props.showSpinner();
    saveEventEvent(newEventData.current)
      .then(() => {
        updateEventData();
        setEditedEventData(null);
        props.hideCustomMsgBox();
      })
      .catch((error) => props.handleError(error, 'Error, Event not created'))
      .finally(() => props.hideSpinner());
  };

  const updateNewEvent = (data) => {
    newEventData.current = data;
  };

  const updateEventData = () => {
    props.showSpinner();
    getEventData(ptsEventID)
      .then((data) => {
        setNotFound(false);
        setRawData(data);
      })
      .catch((error) => {
        props.handleError(error, 'Error, event not updated');
        setNotFound(true);
      })
      .finally(() => props.hideSpinner());
  };

  // Notes
  // const showAddNewNote = () => {
  //   if (editedNotesData) return;
  //   setEditedNotesData('');
  // };

  // const removeEditedNote = () => {
  //   setEditedNotesData(false);
  // };

  // const saveNote = () => {
  //   if (!editedNotesData) return;
  //   props.showSpinner();
  //   saveEventNote(editedNotesData, ptsEventID)
  //     .then(() => {
  //       updateEventData();
  //       setEditedNotesData(false);
  //     })
  //     .catch((error) => props.handleError(error, 'Error, note not saved.'))
  //     .finally(() => props.hideSpinner());
  // };

  // const updateNewNote = (data) => {
  //   setEditedNotesData(data);
  // };

  // const showEditNote = (ptsCommentID) => {
  //   if (editedNotesData) return;
  //   const noteData = notesData.find((note) => note.ptsCommentID === ptsCommentID);
  //   setEditedNotesData({ ...noteData });
  // };

  // const removeNote = (ptsCommentID) => {
  //   if (window.confirm('Are you sure you want to delete this note?')) {
  //     props.showSpinner();
  //     removeEventNote(ptsCommentID)
  //       .then(() => updateEventData())
  //       .catch((error) => props.handleError(error, 'Error, note not removed'))
  //       .finally(() => props.hideSpinner());
  //   }
  // };

  const pushToLegacy = async () => {
    props.pushEvToLegacyRMS(ptsEventID, true);
    setPushToLegacyActive(false);
  };

  const close = () => {
    const isClosedEvent = !props.events.find((e) => e.ptsEventID === ptsEventID);
    if (isClosedEvent) {
      props.cleanEventSort(ptsEventID);
    }
    props.closeEditEvent();
  };

  const renderActions = () => {
    return (
      <div className={classes.actions}>
        <div className={classes.leftActions}>
          {showPushToLegacy && (
            <Tooltip title="Push to legacy RMS">
              <span>
                <IconButton
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={pushToLegacy}
                  disabled={!pushToLegacyActive}>
                  <DeviceHubIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </div>
        <div className={classes.rightActions}>
          <Button onClick={close} color="primary" size="small">
            <CloseIcon /> Close
          </Button>
        </div>
      </div>
    );
  };

  if (notFound)
    return (
      <h3>
        Error, event <span className={classes.evNotFound}>{ptsEventID}</span> not found!
      </h3>
    );
  if (!rawData) return <h3>Loading...</h3>;

  return (
    <Dialog
      toolbar
      onClose={close}
      title={`Edit Event - ${rawData.Event.EventID}`}
      actions={renderActions()}>
      <div className={classes.contentWrap}>
        <CustomMsgBox />

        {!!editedLocationData && (
          <LocationForm
            dictionary={dictionary}
            data={editedLocationData}
            title={editedLocationData.ptsLocationID ? 'Edit Location' : 'New Location'}
            clear={clearEditedLocation}
            save={saveLoc}
            addPlace={addPlace}
            updateData={updateNewLocation}
            clearPlace={clearPlace}
          />
        )}
        {!editedLocationData && (
          <LocationsCard
            data={rawData.Locations}
            add={showAddNewLocation}
            edit={showEditLocation}
            edited={!!editedLocationData}
            remove={removeLocation}
            disableEdit={disableEdit}
          />
        )}
        {!editedEventData && (
          <EventCard
            dictionary={dictionary}
            data={rawData.Event}
            edit={showEditEvent}
            edited={!!editedEventData}
            disableEdit={disableEdit}
          />
        )}
        {!!editedEventData && (
          <EventForm
            dictionary={dictionary}
            data={rawData.Event}
            setValue={setEventValue}
            clear={clearEditedEvent}
            title="Edit Event"
            save={saveEvent}
            updateData={updateNewEvent}
          />
        )}
        <EditEventTabs ptsEventID={ptsEventID} disableEdit={disableEdit} />
      </div>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    events: state.events,
    websocket: state.websocket,
    dictionary: state.dictionary,
    formSendStatus: state.notifier.formSendStatus,
    formOrigin: state.notifier.formOrigin,
  };
};

export default connect(mapStateToProps, {
  showSpinner,
  hideSpinner,
  notify,
  showCustomMsgBox,
  hideCustomMsgBox,
  handleError,
  closeEditEvent,
  pushEvToLegacyRMS,
  cleanEventSort,
})(EditEvent);
