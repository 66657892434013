import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import settings from '../../config/settings';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ZoneTable from './ZoneTable';
import CustomDialog from '../../components/CustomDialog';
import ZoneEdit from './ZoneEdit';
import { getZones, delZone, saveZone } from '../../reducers/ZonesReducer';
import { notify } from '../../reducers/NotifierReducer';
import Tooltip from '../../components/Tooltip';
import { handleError } from '../../reducers/ErrorReducer';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: `calc(100vh - ${settings.mainNavHeight + theme.spacing(3)}px)`,
  },
  panel: {
    height: '100%',
    overflow: 'auto',
    display: 'block',
    position: 'relative',
    width: '100%',
    boxSizing: 'border-box',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

function Panel(props) {
  const classes = useStyles();
  const { children, ...other } = props;
  return (
    <PerfectScrollbar className={classes.panel} {...other}>
      <Box p={3}>{children}</Box>
    </PerfectScrollbar>
  );
}

function Zones(props) {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState('tab-all');
  const [zoneData, setZoneData] = useState([]);
  const [zones, setZones] = useState([]);
  const [edited, setEdited] = useState(null);
  const [editedValid, setEditedValid] = useState(false);
  const { dictionary } = props;
  const editedDataRef = useRef();

  useEffect(() => {
    updateZones();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Filter zones depending on current tab
    if (!zoneData || !zoneData.length) return;
    if (tabValue === 'tab-all') {
      return setZones(zoneData);
    }
    const filteredZones = zoneData.filter((zone) => zone.AgencyID === tabValue);
    setZones(filteredZones);
    // eslint-disable-next-line
  }, [zoneData, tabValue]);

  const updateZones = () => {
    getZones()
      .then((zones) => {
        setZoneData(zones);
      })
      .catch((err) => props.handleError(err, 'Cannot contact API'));
  };

  const tabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const editZone = (zone) => {
    setEdited(zone);
  };

  const deleteZone = (zone) => {
    if (!window.confirm(`Are you sure you want to delete zone ${zone.ZoneCode}?`)) return;
    delZone(zone.ROWGUID)
      .then(() => {
        props.notify('Zone deleted', 'info');
        updateZones();
      })
      .catch((err) => props.handleError(err, 'Cannot contact API'));
  };

  const addZone = () => {
    setEdited({
      Flags: true,
      ZoneCode: '',
      Description: '',
      AgencyID: null,
    });
  };

  const saveEditedZone = () => {
    if (!editedValid) return;
    saveZone(editedDataRef.current)
      .then(() => {
        props.notify('Zone added', 'success');
        updateZones();
      })
      .catch((err) => props.handleError(err, 'Cannot contact API'));
    closeEdit();
  };

  const updateEditedData = (data) => {
    editedDataRef.current = data;
    if (!!editedDataRef.current !== editedValid) {
      setEditedValid(!!editedDataRef.current);
    }
  };

  const closeEdit = () => {
    setEdited(null);
    editedDataRef.current = null;
  };

  const actions = (
    <div>
      {editedValid && (
        <Tooltip title="Save changes">
          <Button autoFocus color="inherit" onClick={saveEditedZone}>
            save
          </Button>
        </Tooltip>
      )}
      {!editedValid && (
        <Button autoFocus color="inherit" onClick={saveEditedZone} disabled>
          save
        </Button>
      )}
    </div>
  );

  return (
    <div>
      <div className={classes.root}>
        <CustomDialog
          open={!!edited}
          close={closeEdit}
          title={edited && edited.ROWGUID ? 'Edit Zone' : 'Add Zone'}
          actions={actions}
          toolbar>
          <ZoneEdit data={edited} update={updateEditedData} />
        </CustomDialog>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={tabValue}
          onChange={tabChange}
          className={classes.tabs}>
          <Tab label="All" value="tab-all" />
          {dictionary.Agencies.map((agency) => (
            <Tab key={agency.AgencyID} label={agency.AgencyID} value={agency.AgencyID} />
          ))}
        </Tabs>
        <Panel>
          <ZoneTable rows={zones} editZone={editZone} deleteZone={deleteZone} addZone={addZone} />
        </Panel>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    dictionary: state.dictionary,
  };
};

export default connect(mapStateToProps, { notify, handleError })(Zones);
