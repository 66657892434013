import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardContent, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { setPasswordFieldShouldReset, submitLoginForm } from 'reducers/UserReducer';
import projectLogo from 'assets/images/icon-CAD.svg';
import ptsLogo from 'assets/images/PTS-logo.png';
import splashScreen from 'assets/images/login-splash.jpg';
import { checkForUpdates } from 'reducers/AppInfoReducer';
import TextField2 from 'components/TextField2';
import { validateEmail } from 'utils/functions';
import { notify } from 'reducers/NotifierReducer';
import { sendPassResetLink } from 'reducers/UserReducer';

const useStyles = makeStyles((theme) => ({
  pageWrap: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${splashScreen})`,
    backgroundSize: 'cover',
  },
  card: {
    width: 450,
    maxWidth: '95%',
    background: '#fff',
    '& img': {
      width: '100%',
    },
  },
  content: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  branding: {
    width: '100%',
    height: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#ce0000',
    '& img': {
      width: 150,
      height: 'auto',
    },
  },
  branding2: {
    width: '100%',
    textAlign: 'center',
    padding: '25px 0 5px',
    '& img': {
      height: 50,
      width: 'auto',
    },
  },
  formWrap: {
    '& .MuiInputBase-root': {
      color: '#333',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      boxShadow: 'none',
      textFillColor: 'inherit',
    },
  },
  actions: {
    textAlign: 'center',
    marginTop: 30,
    '& button': {
      background: '#ce0000',
      paddingLeft: '4em',
      paddingRight: '4em',
      textTransform: 'uppercase',
      '&:hover': {
        background: '#a80000',
      },
    },
    '& button.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.26)',
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
    },
  },
  version: {
    position: 'absolute',
    bottom: '0.5em',
    left: '1em',
    lineHeight: 1,
  },
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 1,
    height: 1,
    border: 'none',
    background: 'white',
    opacity: 0.01,
  },
  passReset: {
    textAlign: 'right',
    '& span': {
      fontSize: 13,
      color: theme.colors.grey3,
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  textField: {
    width: '100%',
  },
}));

const wrkKey = process.env.REACT_APP_WORSTATION_KEY;
const savePass =
  process.env.REACT_APP_SAVE_PASS && process.env.REACT_APP_SAVE_PASS.toLowerCase() === 'true'
    ? true
    : false;

function getSettingsLogoutUrl() {
  let url = process.env.REACT_APP_SETTINGS_URL;
  if (!url) return false;
  if (url.substr(-1) !== '/') url += '/';
  url += 'logout';
  return url;
}

const logoutSettingsUrl = getSettingsLogoutUrl();

const PageLogin = (props) => {
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [key, setKey] = useState('');
  const [wrkPassed, setWrkPassed] = useState(!wrkKey);
  const [view, setView] = useState('login'); // login, pass-reset
  const { appInfo } = props;
  const { appVersion } = appInfo;
  console.log('version ', appVersion);
  useEffect(() => {
    props.checkForUpdates();
    checkWrkPass();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!wrkKey) return;
    if (key === wrkKey) {
      window.localStorage.setItem('wrk-ps', window.btoa(key));
      window.location.reload();
    }
    // eslint-disable-next-line
  }, [key]);

  useEffect(() => {
    if (props.passwordFieldShouldReset) {
      setPassword('');
    }
    props.setPasswordFieldShouldReset(false);
  }, [props.passwordFieldShouldReset]);

  const checkWrkPass = () => {
    const savedWrkKey = window.atob(window.localStorage.getItem('wrk-ps'));
    if (savedWrkKey === wrkKey && !wrkPassed) setWrkPassed(true);
  };

  const handleUsernameChange = (event) => setUsername(event.target.value);

  const handlePasswordChange = (event) => setPassword(event.target.value);

  const submitForm = (event) => {
    event.preventDefault();
    props.submitLoginForm(username, password);
  };

  const passResetSubmit = async (event) => {
    event.preventDefault();
    try {
      await sendPassResetLink(email);
      setEmail('');
      setView('login');
      props.notify(
        'Reset password link will be sent to the email provided, if it exists in our database.'
      );
    } catch (err) {
      console.log(err);
      props.notify('Error sending password reset link', 'error');
    }
  };

  const renderWorkstationKey = () => (
    <div className={classes.formWrap}>
      <TextField2
        variant="outlined"
        label="Workstation Key"
        placeholder="Enter your Workstation Key"
        type="mask"
        value={key}
        onChange={(ev) => setKey(ev.target.value)}
        className={classes.textField}
      />
    </div>
  );

  const renderLoginForm = () => (
    <form onSubmit={submitForm}>
      <div className={classes.formWrap}>
        <TextField2
          required
          variant="outlined"
          label="Username"
          placeholder="Enter your username"
          type="text"
          value={username}
          onChange={handleUsernameChange}
          className={classes.textField}
        />
      </div>
      <div className={classes.formWrap}>
        <TextField2
          inputRef={(input) => props.passwordFieldShouldReset === true && input?.focus()}
          required
          variant="outlined"
          label="Password"
          placeholder="Enter your password"
          type={savePass ? 'password' : 'mask'}
          value={password}
          onChange={handlePasswordChange}
          className={classes.textField}
        />
      </div>
      <div className={classes.passReset}>
        <span onClick={() => setView('pass-reset')}>Password reset</span>
      </div>
      <div className={classes.actions}>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          size="large"
          className={classes.btn}>
          Login
        </Button>
      </div>
    </form>
  );

  const renderPassReset = () => {
    const valid = validateEmail(email);
    const error = valid || !email ? '' : 'Please enter valid e-mail address';
    return (
      <form onSubmit={passResetSubmit}>
        <div className={classes.formWrap}>
          <TextField2
            required
            variant="outlined"
            label="E-mail address"
            fullWidth
            placeholder="Enter your e-mail address"
            value={email}
            onChange={(ev, val) => setEmail(val.toLowerCase())}
            autoComplete="email"
            size="small"
            type="email"
            className={classes.textField}
            error={error}
          />
        </div>
        <div className={classes.passReset}>
          <span onClick={() => setView('login')}>Sign in</span>
        </div>
        <div className={classes.actions}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            size="large"
            disabled={!valid}
            className={classes.btn}>
            Send reset link
          </Button>
        </div>
      </form>
    );
  };

  return (
    <div className={classes.pageWrap}>
      {Boolean(logoutSettingsUrl) && <iframe src={logoutSettingsUrl} className={classes.iframe} />}
      <Card className={classes.card}>
        <div className={classes.branding}>
          <img src={projectLogo} alt="CAD icon" />
        </div>
        <div className={classes.branding2}>
          <img src={ptsLogo} alt="PTS logo" />
        </div>
        <CardContent className={classes.content}>
          {!wrkPassed && renderWorkstationKey()}
          {wrkPassed && (
            <>
              {view === 'login' && renderLoginForm()}
              {view === 'pass-reset' && renderPassReset()}
            </>
          )}
          <div className={classes.version}>{appVersion}</div>
        </CardContent>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  themeMode: state.theme.mode,
  appInfo: state.appInfo,
  passwordFieldShouldReset: state.user.passwordFieldShouldReset,
});

export default connect(mapStateToProps, {
  submitLoginForm,
  checkForUpdates,
  notify,
  setPasswordFieldShouldReset,
})(PageLogin);
