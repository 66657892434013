import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { handleError } from 'reducers/ErrorReducer';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from 'components/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import Fab from '@material-ui/core/Fab';
import { showAddAttachment, showReport } from 'reducers/DialogsReducer';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { showEditPlace } from 'reducers/DialogsReducer';
import XGrid3 from 'components/XGrid3';
import LocalPrintshopRoundedIcon from '@material-ui/icons/LocalPrintshopRounded';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import { getAddressPlace, removeAddressPlace } from 'reducers/AddressReducer';
import PlaceLookup from 'components/PlaceLookup';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button } from '@material-ui/core';
import { getService } from 'reducers/service';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { notify } from 'reducers/NotifierReducer';
import Spinner from 'components/Spinner';

const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filter: {
    width: '100%',
    marginBottom: '8px',
  },
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    marginBottom: 10,
  },
  panel: {
    '& .MuiDataGridPanelFooter-root': {
      display: 'none',
    },
  },
}));

const colsWithoutDelete = [
  'PlaceID',
  'PlaceName',
  'PlaceType',
  'PlaceORI',
  'PlaceDepartmentID',
  'FullAddressText',
  'Created',
  'CreatedBy',
  'Updated',
  'UpdatedBy',
];

const colsWithDelete = [
  'PlaceID',
  'PlaceName',
  'PlaceType',
  'PlaceORI',
  'PlaceDepartmentID',
  'FullAddressText',
  'Created',
  'CreatedBy',
  'Updated',
  'UpdatedBy',
  'IsDeleted',
  'Deleted',
  'DeletedBy',
  'DeletedIP',
];

function Place(props) {
  const classes = useStyles();
  const { dataUpdate, ptsAddressID } = props;
  const permissions = getFullPermissions('globals', 'Places', 'any');
  const userColumns = permissions.ViewDeleted ? colsWithDelete : colsWithoutDelete;
  const [rows, setRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [loaded, setLoaded] = useState(true);
  const [placeColumns, setPlaceColumns] = useState(userColumns);
  const [printFlag, setPrintFlag] = useState(false);
  const [place, setPlace] = useState(null);
  const mountedRef = useRef(true);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!dataUpdate || dataUpdate.type !== 'Place') return;
    getData();
    // eslint-disable-next-line
  }, [dataUpdate]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [ptsAddressID]);

  const getData = async () => {
    try {
      const data = await getAddressPlace(ptsAddressID);
      if (!mountedRef.current) return;
      setRows(processData(data));
      setLoaded(true);
    } catch (err) {
      props.handleError(err, 'Error receiving event location data.');
    }
  };

  const processData = (data) => {
    return data.map((row, idx) => {
      return {
        ...row,
        id: row.ROWGUID,
      };
    });
  };

  const viewDetails = () => {
    let item = rows.find((row) => row.ROWGUID === selection);
    if (!item) return;
    props.showReport({ id: 30, data: { ptsPlaceID: item.ptsPlaceID }, title: 'Place Details' });
  };

  const addAttachment = () => {
    const item = rows.find((row) => row.ROWGUID === selection);
    const { ptsPlaceID } = item;
    props.showAddAttachment({ id: ptsPlaceID, type: 'Place' });
  };

  const add = () => props.showEditPlace({ ptsPlaceID: null, ptsAddressID });

  const edit = () => {
    const item = rows.find((row) => row.ROWGUID === selection);
    if (!item) return;
    const { ptsPlaceID } = item;
    props.showEditPlace({ ptsPlaceID, ptsAddressID: null });
  };
  const delPlace = async () => {
    if (!window.confirm('Are you sure you want to delete this record?')) return;
    setLoaded(false);
    try {
      await removeAddressPlace(selection);
      if (!mountedRef.current) return;
      await getData();
    } catch (err) {
      props.handleError(err);
    }
  };

  const save = async () => {
    setLoading(true);
    try {
      const { ptsPlaceID } = place;
      const service = getService('cad-address-place');
      await service.create({ ptsPlaceID, ptsAddressID });
      props.notify('Place Updated', 'success');
      props.notifyDataUpdate({ type: 'Place', data: { action: 'Update' } });
      setPlace(null);
    } catch (error) {
      props.handleError(error);
    }
    setLoading(false);
  };
  const handlePrintClick = () => {
    setPrintFlag(true);
    setTimeout(() => setPrintFlag(false), 1000);
  };
  const renderPlace = () => {
    const handleChange = (value) => setPlace(value);
    return (
      <div style={{ margin: 10 }}>
        <PlaceLookup onChange={handleChange} className={classes.item} label="Place" />
      </div>
    );
  };

  return (
    <div>
      {renderPlace()}
      <div style={{ marginLeft: 10 }}>
        <Button disabled={!place} variant="outlined" onClick={save}>
          Save
        </Button>
      </div>
      <div className={classes.actions}>
        <Tooltip title="Print list">
          <Fab size="small" color="secondary" onClick={handlePrintClick}>
            <LocalPrintshopRoundedIcon />
          </Fab>
        </Tooltip>
        <Tooltip title="Add place">
          <Fab className="ml-2" size="small" color="secondary" onClick={add}>
            <AddIcon />
          </Fab>
        </Tooltip>
        {selection !== null && (
          <>
            <Tooltip title="Edit place">
              <Fab className="ml-2" size="small" color="secondary" onClick={edit}>
                <EditIcon />
              </Fab>
            </Tooltip>
            <Tooltip title="View details">
              <Fab className="ml-2" size="small" color="secondary" onClick={viewDetails}>
                <InfoIcon />
              </Fab>
            </Tooltip>
            <Tooltip title="Add attachment">
              <Fab className="ml-2" color="secondary" size="small" onClick={addAttachment}>
                <AttachFileIcon />
              </Fab>
            </Tooltip>
            <Tooltip title="Delete party">
              <Fab size="small" color="secondary" onClick={delPlace}>
                <DeleteIcon />
              </Fab>
            </Tooltip>
          </>
        )}
      </div>
      <XGrid3
        name="Address Places"
        columns={placeColumns}
        setColumns={setPlaceColumns}
        rows={rows}
        loading={!loaded}
        setSelection={setSelection}
        printFlag={printFlag}
        viewDetails={viewDetails}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  dataUpdate: state.dataUpdate,
});

export default connect(mapStateToProps, {
  handleError,
  showReport,
  showAddAttachment,
  showEditPlace,
  notifyDataUpdate,
  notify,
})(Place);
