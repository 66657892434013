import React, { useState, useRef, useEffect } from 'react';
import Tooltip from 'components/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { displayDateTime } from 'reducers/TimeReducer';
import { connect } from 'react-redux';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { handleError } from 'reducers/ErrorReducer';
import CheckIcon from '@material-ui/icons/Check';
import XGrid3 from 'components/XGrid3';
import QuickFilterSearch from 'Search/components/QuickFilterSearch';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import AddressLookup from 'components/AddressLookup';
import Checkbox2 from 'components/Checkbox2';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import { savePlaceAddress } from 'reducers/SearchReducer';
import EditIcon from '@material-ui/icons/Edit';
import { showPlaceAddressEdit } from 'reducers/DialogsReducer';
import { removePlaceAddress } from 'reducers/SearchReducer';
import DeleteIcon from '@material-ui/icons/Delete';
import { notify } from 'reducers/NotifierReducer';

const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: theme.spacing(3),
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  fileInput: {
    display: 'none',
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    margin: '20px 0 12px',
  },
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginBottom: 8,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  addressWidget: {
    width: 550,
    display: 'inline-block',
  },
  address: {
    display: 'flex',
    margin: '24px 0 16px',
  },
  addressActions: {
    marginLeft: 8,
    '& button': {
      marginLeft: 8,
    },
    '& svg': {
      marginRight: 8,
    },
  },
}));
const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const defaultColumns = [
  {
    field: 'IsPrimary',
    headerName: 'IsPrimary',
    width: 100,
    format: 'bool',
    renderCell: bool,
    hide: false,
  },
  { field: 'AddressID', headerName: 'Address ID', width: 150, hide: false },
  { field: 'FullAddressText', headerName: 'Full Address', width: 150, hide: false },
  { field: 'AddressNumber', headerName: 'Address Number', width: 150, hide: false },
  { field: 'StreetName', headerName: 'Street Name', width: 150, hide: false },
  { field: 'StreetType', headerName: 'Street Type', width: 150, hide: false },
  { field: 'City', headerName: 'City', width: 150, hide: false },
  { field: 'State', headerName: 'State', width: 150, hide: false },
  { field: 'PostalCode', headerName: 'Postal Code', width: 150, hide: false },
  { field: 'PostalCodeExtension', headerName: 'Extension', width: 150, hide: false },
  { field: 'Country', headerName: 'Country', width: 150, hide: false },
  { field: 'AddressCategory', headerName: 'Address Category', width: 150, hide: false },
  {
    field: 'Created',
    headerName: 'Created',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 150, hide: false },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 150, hide: false },
];
const PlaceAddress = (props) => {
  const classes = useStyles();
  const { ptsPlaceID } = props;
  const [rows, setRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [tables, setTables] = useState(null);
  const [colsSeq, setColsSeq] = useState([]);
  const [columns, setColumns] = useState(defaultColumns);
  const [editing, setEditing] = useState(false);
  const [address, setAddress] = useState(null);
  const [IsPrimary, setIsPrimary] = useState(false);
  const [primaryAvail, setPrimaryAvail] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const mountedRef = useRef(true);

  useEffect(() => {
    const data = processData(props.addressData);
    setRows(data);
    setLoaded(true);
  }, [props.addressData]);

  const processData = (data) => {
    return data.map((row, idx) => {
      return {
        ...row,
        id: row.ptsAddressID,
      };
    });
  };

  const onAddAddress = (address) => {
    setAddress(address);
  };

  const onAddClick = () => {
    setEditing(true);
    setAddress(null);
    const primaryAvail = rows.reduce((res, obj) => (obj.IsPrimary ? false : res), true);
    setPrimaryAvail(primaryAvail);
    const IsPrimary = primaryAvail ? true : false;
    setIsPrimary(IsPrimary);
  };

  const onEditClick = () => props.showPlaceAddressEdit(selection);

  const onDeleteClick = async () => {
    if (!window.confirm('Are you sure you want to delete selected address?')) return;
    try {
      await removePlaceAddress({ ptsPlaceID, ptsAddressID: selection });
      const updatedRows = rows.filter((row) => row.ptsAddressID !== selection);
      setRows(updatedRows);
      props.notify('Address removed', 'success');
    } catch (err) {
      props.handleError(err, 'Error, Unable to remove address');
    }
  };

  const clearPlace = () => {
    setAddress(null);
  };

  const save = () => {
    const data = {
      ptsPlaceID,
      ptsAddressID: address.ptsAddressID,
      IsPrimary,
    };
    savePlaceAddress(data)
      .then(() => {
        props.setNeedRefresh(!props.needRefresh);
        setAddress(false);
        setEditing(false);
      })
      .catch(props.handleError);
  };

  const renderIsPrimary = () => {
    const onChange = () => setIsPrimary(!IsPrimary);
    return (
      <Checkbox2
        checked={IsPrimary}
        onChange={onChange}
        label="Is Primary"
        disabled={!primaryAvail}
      />
    );
  };

  const renderActions = () => {
    return (
      <div className={classes.addressActions}>
        <Button color="primary" variant="contained" autoFocus onClick={save} disabled={!address}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={() => setEditing(false)}>
          <CloseIcon /> Close
        </Button>
      </div>
    );
  };

  return (
    <div className={classes.wrap}>
      <div className={classes.filterWrap}>
        {editing && (
          <>
            <div className={classes.form}>
              <AddressLookup
                className={classes.addressWidget}
                ptsPlaces={false}
                ptsAddresses={true}
                googleAddresses={false}
                onPlaceValueSet={onAddAddress}
                onAddressValueSet={onAddAddress}
                onReset={clearPlace}
                label="Search Address"
                compact="true"
              />
              {renderIsPrimary()}
            </div>
            <div>{renderActions()}</div>
          </>
        )}
        {!editing && (
          <>
            <div className={classes.filterWrap}>
              <QuickFilterSearch rows={rows} columns={columns} setFilteredRows={setFilteredRows} />
            </div>
            <div className={classes.actions}>
              <Tooltip title="Add Address">
                <Fab size="small" color="secondary" onClick={onAddClick}>
                  <AddIcon />
                </Fab>
              </Tooltip>
              {selection && (
                <>
                  <Tooltip title="Edit Address" className="ml-2">
                    <Fab size="small" color="secondary" onClick={onEditClick}>
                      <EditIcon />
                    </Fab>
                  </Tooltip>
                  <Tooltip title="Delete Address" className="ml-2">
                    <Fab size="small" color="secondary" onClick={onDeleteClick}>
                      <DeleteIcon />
                    </Fab>
                  </Tooltip>
                </>
              )}
            </div>
          </>
        )}
      </div>
      <XGrid3
        name="PlaceAddress"
        columns={columns}
        setColumns={setColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    editPeopleEmployment: state.dialogs.editPeopleEmployment,
  };
};
export default connect(mapStateToProps, {
  handleError,
  showSpinner,
  hideSpinner,
  showPlaceAddressEdit,
  notify,
})(PlaceAddress);
