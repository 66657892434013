import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import Tooltip from '../../components/Tooltip';
import RebidsMapPanel from './RebidsMapPanel';
import EventMap from './EventMap';
import EventForm from './EventForm';
import CallerForm from './CallerForm';
import LocationForm from './LocationForm';
import InfoPanel from './InfoPanel';
import Badge from '@material-ui/core/Badge';
import {
  newEvent,
  getEventData,
  saveEventNote,
  saveEventCaller,
  saveLocation,
  saveEventEvent,
  unitInitiatedEvent,
  getEventDuplicates,
} from 'reducers/EventsReducer';
import { notify, showCustomMsgBox, hideCustomMsgBox } from 'reducers/NotifierReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import CustomMsgBox from 'components/CustomMsgBox';
import {
  locationPrimaryEmptyData,
  callerEmptyData,
  eventEmptyData,
  notesEmptyData,
} from './eventEmptyData';
import { asyncForEach } from 'utils/functions';
import { addCoordsToLocation } from 'utils/mapFunctions';
import { handleError } from 'reducers/ErrorReducer';
import Dialog from 'components/Dialog';
import EventDuplicates from './EventDuplicates';
import AddressDuplicates from './AddressDuplicates';
import {
  dispRecommendations,
  closeAddEvent,
  editEvent,
  showSops,
  showAddressDialog,
} from 'reducers/DialogsReducer';
import settings from 'config/settings';
import { geocodeLocation, getAddressFromLocation, getCoords } from 'utils/mapFunctions';
import {
  getAddressDuplicates,
  getLocationHistoryCount,
  getAddressCoordinates,
} from 'reducers/AddressReducer';
import NotesPanel from './NotesPanel';
import { AiFillPushpin } from 'react-icons/ai';
import { AiOutlinePushpin } from 'react-icons/ai';
import { togglePin } from 'reducers/EventSortingReducer';
import { getAddressZones } from 'reducers/ZonesReducer';
import { getESNMatchByAddress, getEventESNMatch } from 'reducers/EsnReducer';
import { sortObjArr } from 'utils/functions';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { showReport } from 'reducers/DialogsReducer';
import { sendRipAndRun } from 'reducers/ReportsReducer';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import { formatSaveDate, parseInDate } from 'reducers/TimeReducer';
import { getService } from 'reducers/service';
import { setEventLocation } from 'reducers/EventLocationReducer';
import { saveEventLocation } from 'reducers/EventsReducer';
const useStyles = makeStyles((theme) => ({
  seconderyBar: {
    display: 'flex',
    width: '100%',
    justifyContent: 'spaces-between',
    alignItems: 'center',
    '& > div': {
      '&:first-child': {
        width: 'calc(50% - 45px)',
      },
      '&:nth-child(2)': {
        textAlign: 'center',
        width: 90,
        flex: '1 0 90px',
      },
      '&:last-child': {
        textAlign: 'right',
        width: 'calc(50% - 45px)',
      },
    },
  },
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    '& button': {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
  },
  leftActions: {
    '& button': {
      '& svg': {
        marginRight: 0,
      },
    },
  },
}));

function AddEvent(props) {
  const classes = useStyles();
  const { dictionary, data, events, eventSort, options } = props;
  const evDuplicateOpts = options.EventDuplicateCheck;
  const addrDuplicateOpts = options.AddressDuplicateCheck;
  const { RipAndRunEnabled, EventAutomation } = options;
  const {
    CopyPlaceToNotes,
    CopyEventsAtAddressToNotes,
    CopyAddressDescriptionToNotes,
  } = EventAutomation;
  const eventPins = eventSort.pins;
  const CallerPhone = data?.Event?.CallerPhone || null;
  const type = data.type;
  const [eventData, setEventData] = useState({ ...eventEmptyData });
  const [callersData, setCallersData] = useState([{ ...callerEmptyData }]);
  const [locationsData, setLocationsData] = useState([{ ...locationPrimaryEmptyData }]);
  const [notesData, setNotesData] = useState([{ ...notesEmptyData }]);
  const [parsedLocation, setParsedLocation] = useState(null);
  const [ptsEventID, setPtsEventID] = useState(null);
  const [sopsNo, setSopsNo] = useState(0);
  const [dispRectActive, setDispRectActive] = useState(false);
  const [eventDuplicates, setEventDuplicates] = useState([]);
  const [addressDuplicates, setAddressDuplicates] = useState([]);
  const [AddressHistory, setAddressHistory] = useState(0);
  const [ptsAddressID, setPtsAddressID] = useState(null);
  const [eventPinned, setEventPinned] = useState(false); // only new events
  const [priority, setPriority] = useState(null);
  const [zones, setZones] = useState([]);
  const [esns, setEsns] = useState([]);
  const [locationDescription, setLocationDescription] = useState('');
  const [chkAddrDuplicates, setChkAddrDuplicates] = useState(false);
  const [chkEvDuplicates, setChkEvDuplicates] = useState(false);
  const [addrDuplError, setAddrDuplError] = useState(null);
  const newEventData = useRef({ ...eventEmptyData });
  const newCallersData = useRef([{ ...callerEmptyData }]);
  const newLocationData = useRef([{ ...locationPrimaryEmptyData }]);
  const evDuplicateThrottle = useRef(0);
  const addressDuplicateThrottle = useRef(0);
  const addressHistoryThrottle = useRef(0);
  const zoneCheckThrottle = useRef(0);
  const chAddrDuplicatesRef = useRef(false);
  const chAddrDuplCount = useRef(false);
  const isLatLngAvailable = useRef(true);
  const coordsThrottleRef = useRef(0);
  const mountedRef = useRef(true);
  const geofenceEsn = useRef(false);
  const eventExists = ptsEventID && events.findIndex((ev) => ev.ptsEventID === ptsEventID) !== -1;
  const pinned = eventExists
    ? eventPins.findIndex((ev) => ev.ptsEventID === ptsEventID) !== -1
    : eventPinned;
  const permissions = getFullPermissions('cad', 'Events', 'any');
  const canSave = ptsEventID ? permissions.Edit : permissions.Create;
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      clearTimeout(evDuplicateThrottle.current);
      clearTimeout(addressDuplicateThrottle.current);
      clearTimeout(addressHistoryThrottle.current);
      clearTimeout(zoneCheckThrottle.current);
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!data) return;
    const { Callers, Event, Locations, Notes } = data;
    Event && setEventData(Event);
    Callers && setCallersData(Callers);
    Locations && setParsedLocation(Locations);
    Notes && setNotesData(Notes);
    checkCoords(Event);
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (!ptsEventID) return;
    const ev = events.find((ev) => ev.ptsEventID === ptsEventID);
    if (!ev) {
      setSopsNo(0);
      setDispRectActive(false);
      return;
    }
    setDispRectActive(true);
    const sops = ev.sops;
    if (sops.length) {
      setSopsNo(sops.length);
    } else {
      setSopsNo(0);
    }
    // eslint-disable-next-line
  }, [events]);

  const addSystemNotes = async (ptsEventID, data) => {
    let primaryLocation;
    if (data.Locations) primaryLocation = data.Locations.find((loc) => loc.IsPrimary);
    let place = null;
    if (primaryLocation?.ptsPlaceID) {
      const service = getService('ptsplace');
      try {
        const query = {
          IsDeleted: false,
          ptsPlaceID: primaryLocation.ptsPlaceID,
        };
        const result = await service.find({ query });
        if (result && result.data.length) place = result.data[0];
      } catch (err) {
        props.handleError(err);
      }
    }
    let Comment = 'System Notes:';
    if (CopyPlaceToNotes && place) Comment += `\nPlace - ${place.PlaceName}`;
    if (CopyEventsAtAddressToNotes) Comment += `\nTotal Events - ${AddressHistory}`;
    if (CopyAddressDescriptionToNotes) {
      const locationDescription = data.Event.LocationDescription || '';
      Comment += `\nLocation Description: ${locationDescription}`;
    }
    saveEventNote({ Comment }, ptsEventID);
  };

  const saveEvent = async (open = false, action = null) => {
    props.showSpinner();
    props.hideCustomMsgBox();
    let errors = false;
    if (newEventData?.current?.ptsEventID || ptsEventID) {
      await updateEvent(open, action);
      props.hideSpinner();
      return;
    }

    const data = {};
    data.Event = { ...newEventData.current, AddressHistory };
    if (locationsData.length && !locationsData[0].lat && !locationsData[0].lng) {
      data.Locations = getNonEmptyLocations();
      isLatLngAvailable.current = false;
    } else {
      data.Locations = locationsData;
    }

    const callers = getNonEmptyCallers();
    if (callers) data.Callers = callers;
    const notes = getNonEmptyNotes();
    if (notes.length > 0) data.Notes = notes;

    let id = null;
    try {
      id = await newEvent(data);
      newEventData.current.ptsEventID = id;
      setPtsEventID(id);
      if (eventPinned) props.togglePin(id);
      if (CopyPlaceToNotes || CopyEventsAtAddressToNotes || CopyAddressDescriptionToNotes)
        addSystemNotes(id, data);
    } catch (e) {
      errors = true;
      if (e.errors) {
        const errMessages = [];
        Object.values(e.errors).forEach((msg) => errMessages.push(msg));
        props.showCustomMsgBox('Form validation errors', errMessages, 'error');
      } else {
        props.handleError(e, 'Event saving error');
      }
    }
    if (type === 'unit-initiated') {
      await unitInitEvent(id);
    }
    if (open) {
      await updateEventData(id);
    } else {
      if (!errors) {
        if (!isLatLngAvailable.current) {
          saveEventLocationPromise(newLocationData.current, id)
            .then((data) => console.log('Saving Event loc Successful'))
            .catch((err) => props.handleError(err));
          isLatLngAvailable.current = true;
        }
        props.closeAddEvent();
      }
    }
    if (action) {
      if (action === 'RipAndRun' && id) await ripAndRun(id);
    }
    props.hideSpinner();
  };

  const saveEventLocationPromise = (location, eventID) => {
    return new Promise((resolve, reject) => {
      getLocationsData(location)
        .then(async (data) => {
          const filteredData = data.filter((location) => location.StreetName);
          if (filteredData.length) {
            saveEventLocation(filteredData[0], eventID)
              .then((data) => resolve(data))
              .catch((err) => reject(err));
          } else throw new Error('No Street Name Found');
        })
        .catch((err) => reject(err));
    });
  };

  const ripAndRun = async (ptsEventID) => {
    try {
      await sendRipAndRun(ptsEventID);
      props.notify('Report sent', 'success');
    } catch (err) {
      props.handleError(err);
    }
  };

  const unitInitEvent = async (ptsEventID) => {
    const Occurred = formatSaveDate(parseInDate(data.Unit.Occurred));
    try {
      await unitInitiatedEvent(ptsEventID, data.Unit.ptsUnitID, Occurred);
    } catch (err) {
      props.handleError(err);
    }
  };

  const getLocationsData = async () => {
    const Locations = [];
    const notEmptyLocations = getNonEmptyLocations();
    await asyncForEach(notEmptyLocations, async (location) => {
      let updatedLocation = location;
      try {
        updatedLocation = await addCoordsToLocation(location, dictionary);
      } catch (err) {
        props.handleError(err, 'Error, cannot contact geolocation service', 'error');
      }
      Locations.push(updatedLocation);
    });

    return Locations;
  };

  const updateEvent = async (open, action) => {
    const ptsEventID = newEventData.current.ptsEventID;
    // Event
    try {
      const Event = newEventData.current;
      await saveEventEvent(Event);
    } catch (err) {
      props.handleError(err, 'Error updating event details');
    }
    // Locations
    const Locations = await getLocationsData();
    try {
      await asyncForEach(Locations, async (Location) => {
        await saveLocation(Location, ptsEventID, dictionary);
      });
    } catch (err) {
      props.handleError(err, 'Error updating locations');
    }

    // Callers
    try {
      await asyncForEach(newCallersData.current, async (Caller) => {
        await saveEventCaller(Caller, ptsEventID);
      });
    } catch (err) {
      props.handleError(err, 'Error updating callers');
    }

    // Notes
    try {
      await asyncForEach(notesData, async (Note) => {
        await saveEventNote(Note, ptsEventID);
      });
    } catch (err) {
      props.handleError(err, 'Error updating notes');
    }
    if (!open) return props.closeAddEvent();
    await updateEventData();
    if (action) {
      if (action === 'RipAndRun' && ptsEventID) ripAndRun(ptsEventID);
    }
  };

  const updateEventData = async (id = ptsEventID) => {
    if (!id) return;
    const event = await getEventData(id);
    if (!event) return;
    const { Event, Notes, Callers, Locations } = event;
    const callersData = Callers.length ? Callers : [{ ...callerEmptyData }];
    const locationsData = Locations.length ? Locations : [{ ...locationPrimaryEmptyData }];
    const notesData = Notes.length ? Notes : [{ ...notesEmptyData }];
    newEventData.current = Event;
    newCallersData.current = callersData;
    newLocationData.current = locationsData;
    setEventData(Event);
    setCallersData(callersData);
    setLocationsData(locationsData);
    setNotesData(notesData);
    checkCoords(Event);
  };

  const getNonEmptyLocations = () => {
    return newLocationData.current.filter((location) => location.StreetName);
  };

  const getNonEmptyCallers = () => {
    return newCallersData.current.filter(
      (caller) => caller.FirstName || caller.LastName || caller.Info || caller.CallerLocation
    );
  };

  const getNonEmptyNotes = () => {
    return notesData.filter((note) => note.Comment && note.Comment.trim() !== '');
  };

  const locationCheckMet = (location) => {
    const { AddressNumber, StreetName, ptsCityID, ptsCoordinateID } = location;
    const addressValid = AddressNumber && StreetName && ptsCityID;
    if (
      (evDuplicateOpts.SearchRadiusActive ||
        evDuplicateOpts.Address ||
        evDuplicateOpts.StreetRangeActive) &&
      addressValid
    )
      return true;
    if (evDuplicateOpts.Coordinates && ptsCoordinateID) return true;
    return false;
  };

  const updateNewEvent = (data) => {
    if (evDuplicateOpts.CheckForDuplicates) {
      const callTypeCheck =
        evDuplicateOpts.CallType &&
        data.CallType &&
        newEventData.current.CallType !== data.CallType;
      const locationRequired =
        evDuplicateOpts.Address ||
        evDuplicateOpts.Coordinates ||
        evDuplicateOpts.SearchRadiusActive ||
        evDuplicateOpts.StreetRangeActive;
      if (
        callTypeCheck &&
        ((locationRequired && locationCheckMet(newLocationData.current[0])) || !locationRequired)
      ) {
        newEventData.current = data;
        checkForEvDuplicates();
      }
    }
    newEventData.current = data;
  };

  const addPlace = (place, no) => {
    const newData = [...newLocationData.current];
    newData[no] = { ...newData[no], ...place };
    setLocationsData(newData);
    const { lat, lng } = newData[no];
    if (
      newData[no].IsPrimary &&
      lat !== undefined &&
      lat !== null &&
      lng !== undefined &&
      lng !== null
    ) {
      const newEvent = { ...eventData, lat, lng };
      setEventData(newEvent);
      //checkCoords(newEvent);

      checkESNsByAddress(place)
        .then((esn) => {
          if (esn.length) {
            geofenceEsn.current = false;
            setEsns(esn);
          } else geofenceEsn.current = true;
          checkCoords(newEvent);
        })
        .catch((err) => {
          props.handleError(err);
        });
    }
  };

  const updateNewNotes = (data, no) => {
    const newNotesData = [...notesData];
    newNotesData[no] = data;
    setNotesData(newNotesData);
  };

  const updateNewCaller = (data, no) => {
    newCallersData.current[no] = data;
  };

  const updateNewLocation = (data, no) => {
    checkAddrHistoryCount(data);
    if (evDuplicateOpts.CheckForDuplicates) {
      if (evDuplicateOpts.CallType && !newEventData.current.CallType) {
        newLocationData.current[no] = data;
        getAddrDuplicates();
        return;
      }
      if (locationCheckMet(data)) {
        newLocationData.current[no] = data;
        checkForEvDuplicates();
      } else {
        newLocationData.current[no] = data;
      }
    } else {
      newLocationData.current[no] = data;
    }
    getAddrDuplicates();
    checkESNsByAddress(data)
      .then((esn) => {
        if (esn.length) {
          geofenceEsn.current = false;
          setEsns(esn);
        } else geofenceEsn.current = true;
        checkAddressCoords(data);
      })
      .catch((err) => {
        props.handleError(err);
      });
  };

  const addNote = () => {
    const newData = [...notesData];
    newData.unshift({ ...notesEmptyData });
    setNotesData(newData);
  };

  const useCallerLocation = () => {
    setLocationsData(parsedLocation);
    setParsedLocation(null);
  };

  const removeNoteForm = (no) => {
    const removeNote = () => {
      const newData = [...notesData];
      newData.splice(no, 1);
      setNotesData(newData);
    };
    const content = notesData[no].Comment;
    if (!content || window.confirm('Note is not empty. Are you sure you want to remove it?')) {
      removeNote();
    }
  };

  const cancelEvent = () => {
    if (window.confirm('Are you sure you want to cancel?')) {
      props.closeAddEvent();
    }
  };

  const showDispatchRecommendations = () => props.dispRecommendations(ptsEventID);

  const showSOPs = () => props.showSops(ptsEventID);

  const showAddressHistory = () => props.showAddressDialog({ ptsAddressID, tab: 'history' });

  const togglePin = () => {
    if (eventExists) {
      props.togglePin(ptsEventID);
    } else {
      setEventPinned(!eventPinned);
    }
  };

  const renderActions = () => {
    return (
      <div className={classes.actions}>
        <div className={classes.leftActions}>
          {dispRectActive ? (
            <Tooltip title="Show Dispatch Recommendations">
              <IconButton
                variant="contained"
                color="primary"
                size="small"
                onClick={showDispatchRecommendations}>
                <AssignmentIndIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <IconButton variant="contained" color="primary" size="small" disabled>
              <AssignmentIndIcon />
            </IconButton>
          )}
          {sopsNo > 0 ? (
            <Tooltip title="Show SOPs">
              <IconButton variant="contained" color="primary" size="small" onClick={showSOPs}>
                <Badge badgeContent={sopsNo} color="error" overlap="circle">
                  <AssignmentLateIcon />
                </Badge>
              </IconButton>
            </Tooltip>
          ) : (
            <IconButton variant="contained" color="primary" size="small" disabled>
              <AssignmentLateIcon />
            </IconButton>
          )}
          {Boolean(ptsAddressID) ? (
            <Tooltip title="Address History">
              <IconButton
                variant="contained"
                color="primary"
                size="small"
                onClick={showAddressHistory}>
                <Badge badgeContent={AddressHistory} color="secondary" overlap="circle">
                  <AssignmentIcon />
                </Badge>
              </IconButton>
            </Tooltip>
          ) : (
            <IconButton variant="contained" color="primary" size="small" disabled>
              <AssignmentIcon />
            </IconButton>
          )}
          <Tooltip title="Pin event">
            <IconButton variant="contained" color="primary" size="small" onClick={togglePin}>
              {pinned ? <AiFillPushpin /> : <AiOutlinePushpin />}
            </IconButton>
          </Tooltip>
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => saveEvent(true, 'RipAndRun')}
            disabled={!RipAndRunEnabled || !canSave}>
            <AssignmentTurnedInIcon /> Rip &amp; Run
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => saveEvent(true)}
            disabled={!canSave}>
            <SaveIcon /> Save
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => saveEvent(false)}
            disabled={!canSave}>
            <SaveIcon /> Save and Close
          </Button>
          <Button onClick={cancelEvent} color="primary" size="small">
            <CloseIcon /> cancel
          </Button>
        </div>
      </div>
    );
  };

  const checkAddressCoords = (data) => {
    const { AddressNumber, StreetName, ptsCityID, ptsCoordinateID } = data;
    if (!AddressNumber || !StreetName || !ptsCityID) return;
    clearTimeout(coordsThrottleRef.current);
    coordsThrottleRef.current = setTimeout(() => {
      if (ptsCoordinateID) {
        checkCoordinateID(ptsCoordinateID);
      } else {
        checkAddress(data);
      }
    }, settings.eventDuplicateWait);
  };

  /** Checking for zones, esns... for specific ptsCoordinateID */
  const checkCoordinateID = async (ptsCoordinateID) => {
    try {
      const address = await getAddressCoordinates(ptsCoordinateID);
      const coords = getCoords(address);
      checkCoords(coords);
    } catch (err) {
      props.handleError(err);
    }
  };

  /** Checking for zones, esns... from location */
  const checkAddress = async (location) => {
    try {
      const coords = await geocodeLocation(location);
      checkCoords(coords);
    } catch (err) {
      props.handleError(err);
    }
  };

  const checkESNsByAddress = async (address) => {
    try {
      const { ptsAddressID } = address;
      const ESN = await getESNMatchByAddress(ptsAddressID);
      return ESN;
    } catch (err) {
      props.handleError(err);
    }
  };

  const updateCoords = (data) => {
    const EventData = { ...newEventData.current };
    const { XCoordinate, YCoordinate } = data;
    EventData.lat = YCoordinate;
    EventData.lng = XCoordinate;
    setEventData(EventData);
    checkCoords(EventData);
  };

  const setLocation = (address) => {
    setLocationsData([address]);
    checkAddrHistoryCount(address);
    setAddressDuplicates([]);
  };

  const checkForEvDuplicates = () => {
    if (!evDuplicateOpts.CheckForDuplicates) return;
    clearTimeout(evDuplicateThrottle.current);
    evDuplicateThrottle.current = setTimeout(() => {
      if (!isLocationValid(newLocationData.current[0])) return;
      const coordsNeeded = evDuplicateOpts.Coordinates || evDuplicateOpts.SearchRadiusActive;
      if (coordsNeeded) {
        geocodeLocation(newLocationData.current[0], dictionary)
          .then((result) => {
            if (!mountedRef.current) return;
            getEvDuplicates(result.lat, result.lng);
          })
          .catch((err) => {
            // props.handleError(err, 'Error geocoding the location.');
          });
      } else {
        getEvDuplicates();
      }
    }, settings.eventDuplicateWait);
  };

  const isLocationValid = (location) => {
    const { AddressNumber, StreetName, ptsCityID } = location;
    return AddressNumber && StreetName && ptsCityID;
  };

  const getEvDuplicates = (lat = null, lng = null) => {
    setChkEvDuplicates(true);
    const { CallType } = newEventData.current;
    const { AddressNumber, StreetName, StreetType } = newLocationData.current[0];
    const data = {
      CallType,
      FullAddressText: getAddressFromLocation(newLocationData.current[0], dictionary),
      AddressNumber,
      StreetType,
      StreetName,
      lat,
      lng,
    };
    getEventDuplicates(data)
      .then((duplicates) => {
        if (!mountedRef.current) return;
        setEventDuplicates(duplicates.filter((ev) => ev.ptsEventID !== ptsEventID));
      })
      .catch((err) => {
        props.handleError(err, 'Error receiving event duplicates');
      })
      .finally(() => {
        setChkEvDuplicates(false);
      });
  };

  const checkAddrHistoryCount = (location) => {
    const { AddressNumber, StreetName, StreetType, ptsCityID } = location;
    if (!AddressNumber || !StreetName || !StreetType || !ptsCityID) return;
    clearTimeout(addressHistoryThrottle.current);
    addressHistoryThrottle.current = setTimeout(() => {
      addrHistoryCount(location);
    }, settings.eventDuplicateWait);
  };

  const addrHistoryCount = async (location) => {
    if (chAddrDuplCount.current) {
      checkAddrHistoryCount(location);
      return;
    }
    chAddrDuplCount.current = true;
    try {
      const { ptsAddressID, count } = await getLocationHistoryCount(location, ptsEventID);
      if (!mountedRef.current) return;
      setAddressHistory(count);
      if (ptsAddressID) setPtsAddressID(ptsAddressID);
    } catch (err) {
      props.handleError(err);
    }
    chAddrDuplCount.current = false;
  };

  const checkCoords = (eventData) => {
    if (!eventData) return;
    clearTimeout(zoneCheckThrottle.current);
    zoneCheckThrottle.current = setTimeout(() => {
      const { lat, lng } = eventData;
      checkZones({ lat, lng });
      if (geofenceEsn.current) checkEsns({ lat, lng });
    }, settings.eventDuplicateWait);
  };

  const checkZones = async (coords) => {
    try {
      const location = getPrimaryLocation();
      const ptsAddressID = location ? location.ptsAddressID : null;
      const zones = await getAddressZones({ coords, ptsAddressID });
      if (!mountedRef.current) return;
      const sortedZones = sortObjArr(zones, 'AgencyID');
      setZones(sortedZones);
    } catch (err) {
      props.handleError(err);
    }
  };

  const getPrimaryLocation = () => {
    const locations = newLocationData.current;
    if (!locations || !locations.length) return null;
    if ((locations.length = 1)) return locations[0];
    const primaryLocation = locations.find((l) => l.IsPrimary);
    return primaryLocation ? primaryLocation : locations[0];
  };

  const checkEsns = async (coords) => {
    try {
      const esns = await getEventESNMatch(coords);
      if (!mountedRef.current) return;
      setEsns(esns);
    } catch (err) {
      props.handleError(err);
    }
  };

  const validateAddrDuplicateOpts = (address) => {
    const { StreetName } = address;
    return addrDuplicateOpts.CheckForDuplicates && StreetName;
  };

  const getAddrDuplicates = () => {
    clearTimeout(addressDuplicateThrottle.current);
    addressDuplicateThrottle.current = setTimeout(() => {
      const address = newLocationData.current[0];
      const {
        ptsAddressID,
        AddressNumber,
        PreDirection,
        StreetName,
        StreetType,
        PostDirection,
        ptsCityID,
        State,
        PostalCode,
        ptsPlaceID,
      } = address;
      if (!validateAddrDuplicateOpts(address)) return;
      const emptyAddress = !(
        AddressNumber ||
        PreDirection ||
        StreetName ||
        StreetType ||
        PostDirection ||
        ptsCityID ||
        State ||
        PostalCode ||
        ptsAddressID ||
        ptsPlaceID
      );
      if (emptyAddress) return setAddressDuplicates([]);
      if (chAddrDuplicatesRef.current) {
        getAddrDuplicates();
        return;
      }
      chAddrDuplicatesRef.current = true;
      setChkAddrDuplicates(true);
      getAddressDuplicates(address)
        .then((duplicates) => {
          if (!mountedRef.current) return;
          setAddressDuplicates(duplicates.filter((addr) => addr.ptsAddressID !== ptsAddressID));
          setAddrDuplError(null);
        })
        .catch((err) => {
          if (!mountedRef.current) return;
          const errMsg = err.message || 'Error receiving address duplicates';
          setAddrDuplError(errMsg);
        })
        .finally(() => {
          chAddrDuplicatesRef.current = false;
          setChkAddrDuplicates(false);
        });
    }, settings.eventDuplicateWait);
  };

  const closeDialog = (data) => {
    props.closeAddEvent(data);
  };

  return (
    <Dialog toolbar onClose={closeDialog} title="Add Event" actions={renderActions()} maxWidth="xl">
      <CustomMsgBox />
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={4}>
          {locationsData.map((location, idx) => (
            <LocationForm
              dictionary={dictionary}
              data={location}
              key={idx}
              no={idx}
              addPlace={addPlace}
              updateData={updateNewLocation}
              title={'Address'}
              useCallerLocation={idx === 0 && parsedLocation ? useCallerLocation : undefined}
              locationDescription={locationDescription}
            />
          ))}
          <EventForm
            dictionary={dictionary}
            data={eventData}
            updateData={updateNewEvent}
            setPriority={setPriority}
            setLocationDescription={setLocationDescription}
            title="Event"
          />
        </Grid>
        <Grid item xs={4}>
          {callersData.map((Caller, idx) => (
            <CallerForm
              dictionary={dictionary}
              key={idx}
              data={Caller}
              no={idx}
              title="Caller"
              updateData={updateNewCaller}
            />
          ))}
          <NotesPanel
            notes={notesData}
            dictionary={dictionary}
            updateNewNotes={updateNewNotes}
            addNote={addNote}
            clear={removeNoteForm}
          />
          <InfoPanel zones={zones} esns={esns} priority={priority} />
        </Grid>
        <Grid item xs={4}>
          {data.type === '911' && (
            <RebidsMapPanel
              CallerPhone={CallerPhone}
              update={updateCoords}
              setLocation={setLocation}
            />
          )}
          {data.type !== '911' && <EventMap data={eventData} setLocation={setLocation} />}
          <EventDuplicates data={eventDuplicates} loading={chkEvDuplicates} />
          <AddressDuplicates
            data={addressDuplicates}
            setLocation={setLocation}
            loading={chkAddrDuplicates}
            error={addrDuplError}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    dictionary: state.dictionary,
    events: state.events,
    options: state.config.options,
    eventSort: state.eventSort,
  };
};

export default connect(mapStateToProps, {
  notify,
  showCustomMsgBox,
  hideCustomMsgBox,
  showSpinner,
  hideSpinner,
  handleError,
  closeAddEvent,
  editEvent,
  dispRecommendations,
  showSops,
  showAddressDialog,
  togglePin,
  showReport,
  setEventLocation,
})(AddEvent);
