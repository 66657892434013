import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Unit from 'pages/Units/Unit';
import { setUnitStatus } from 'reducers/UnitStatusReducer';
import { sortObjArr } from 'utils/functions';
import settings from 'config/settings';

const useStyles = makeStyles(() => ({
  units: {
    width: '100%',
    minHeight: 41,
    maxHeight: settings.maxTabHeight,
    overflowY: 'auto',
  },
}));

function EventUnitsTab(props) {
  const classes = useStyles();
  const { colorPalette, UnitStatuses, ptsEventID, sortOption } = props;
  const [units, setUnits] = useState([]);
  const [selection, setSelection] = useState([]);
  const dataPass = useRef({});

  useEffect(() => {
    if (!units) return;

    const newUnits = props.units?.map((unit) => {
      return {
        ...unit,
        UnitStatuses,
        LastActionOccurred: getLastActionOccurred(unit),
      };
    });
    setUnits(sortObjArr(newUnits, 'LastActionOccurred', sortOption));
    // eslint-disable-next-line
  }, [props.units, sortOption]);

  const getLastActionOccurred = (unit) => {
    let history = [];
    if (UnitStatuses) {
      UnitStatuses.forEach((status) => {
        if (status.Unit === unit.Unit) {
          history.push(status);
        }
      });
      history = sortObjArr(history, 'Occurred', 'ASC');
    }
    return history.length ? history[0].Occurred : unit.Occurred;
  };

  const onUnitClick = (ev, unit) => {
    const { ptsUnitID } = unit;
    if (selection && selection[0] === ptsUnitID) return setSelection([]);
    setSelection([unit.ptsUnitID]);
  };

  dataPass.current.selection = selection;
  dataPass.current.setSelection = setSelection;
  dataPass.current.onUnitClick = onUnitClick;
  dataPass.current.colorPalette = colorPalette;

  return (
    <div className={classes.units}>
      {units?.map((unit) => (
        <div key={unit.ptsUnitID}>
          <Unit
            unit={unit}
            ptsEventID={ptsEventID}
            parent="event"
            selected={selection.indexOf(unit.ptsUnitID) !== -1}
            dataPass={dataPass}
          />
        </div>
      ))}
    </div>
  );
}

export default connect(null, { setUnitStatus })(EventUnitsTab);
