import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { sortObjArr } from 'utils/functions';
import { displayDateTime } from 'reducers/TimeReducer';
import { downloadAttachment, removeAttachment } from 'reducers/FileReducer';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from 'components/Tooltip';
import { handleError } from 'reducers/ErrorReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { notify } from 'reducers/NotifierReducer';
import settings from 'config/settings';
import AttachmentIcon from 'components/AttachmentIcon';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { showAddAttachment } from 'reducers/DialogsReducer';
import { getFullPermissions } from 'reducers/PermissionsReducer';

const useStyles = makeStyles((theme) => ({
  attachments: {
    textAlign: 'left',
    maxHeight: settings.maxTabHeight,
    overflowY: 'auto',
  },
  attachment: {
    padding: '10px 15px 5px',
    '& p': {
      fontSize: 13,
      marginBottom: 5,
    },
    borderBottom: `1px solid ${theme.card.hr}`,
  },
  header: {
    '& img': {
      width: 24,
      height: 24,
      borderRadius: 3,
    },
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h4': {
      fontSize: '1em',
      marginBottom: '0.25em',
    },
  },
  imgInfo: {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      marginRight: 10,
    },
  },
  date: {
    minWidth: 150,
    display: 'inline-block',
    textAlign: 'right',
  },
  fileInput: {
    display: 'none',
  },
  actions: {
    padding: '5px 15px',
    textAlign: 'right',
    borderBottom: `1px solid ${theme.card.hr}`,
    '& label': {
      marginBottom: 0,
    },
  },
  attachmentActions: {
    '& button': {
      marginLeft: 5,
    },
  },
  attachmentName: {},
  fileName: {
    fontWeight: 'bold',
  },
}));

function Attachment(props) {
  const classes = useStyles();
  const { attachment, disableEdit } = props;
  const { FileName, FileType, Attached, Description, ptsAttachmentID, CreatedBy } = attachment;

  const perms = getFullPermissions('cad', 'Events', 'any');
  const { Delete } = perms;

  const downloadFileHandler = async () => {
    props.downloadAttachment(ptsAttachmentID, FileName);
  };

  const deleteFileHandler = () => {
    props.deleteAttachment(ptsAttachmentID, FileName);
  };

  return (
    <div className={classes.attachment}>
      <div className={classes.header}>
        <div className={classes.imgInfo}>
          <AttachmentIcon type={FileType} />
          <div>
            <span className={classes.fileName}>{FileName}</span>
            {!!FileType && <span className="ml-3">{FileType}</span>}
            {!!CreatedBy && <span className="ml-3">{CreatedBy}</span>}
            {!!Description && <p>{Description}</p>}
          </div>
        </div>
        {!disableEdit && (
          <div className={classes.attachmentActions}>
            <Tooltip title="Download attachment">
              <IconButton onClick={downloadFileHandler} size="small">
                <GetAppIcon fontSize="inherit" color="primary" />
              </IconButton>
            </Tooltip>
            {Delete && (
              <Tooltip title="Delete attachment">
                <span>
                  <IconButton
                    color="primary"
                    component="span"
                    onClick={deleteFileHandler}
                    size="small"
                    disabled={!Delete}>
                    <DeleteIcon fontSize="inherit" color="primary" />
                  </IconButton>
                </span>
              </Tooltip>
            )}
            <span className={classes.date}>{displayDateTime(Attached)}</span>
          </div>
        )}
      </div>
    </div>
  );
}

function AttachmentsTab(props) {
  const classes = useStyles();
  const { ptsEventID, sortOption, disableEdit } = props;
  const [attachments, setAttachments] = useState([]);

  const perms = getFullPermissions('cad', 'Events', 'any');
  const { Create } = perms;

  useEffect(() => {
    if (!props.attachments || props.attachments.length === 0) {
      setAttachments([]);
      return;
    }
    setAttachments(sortObjArr(props.attachments, 'FileName', sortOption));
    // eslint-disable-next-line
  }, [props.attachments, sortOption]);

  const deleteAttachment = (ptsAttachmentID, FileName) => {
    if (!window.confirm(`Are you sure to delete file: ${FileName} `)) return;
    props.showSpinner();
    removeAttachment(ptsAttachmentID)
      .then(() => {
        props.notify('File deleted', 'info');
        props.notifyDataUpdate({ type: 'event', data: ptsEventID });
      })
      .catch((err) => {
        props.handleError(err, 'Error, File not deleted.');
      })
      .finally(() => props.hideSpinner());
  };

  const handleDownloadAttachment = async (ptsAttachmentID, FileName) => {
    props.showSpinner();
    try {
      await downloadAttachment(ptsAttachmentID, FileName);
    } catch (err) {
      props.handleError(err);
    }
    props.hideSpinner();
  };

  const onAddAttachment = () => {
    props.showAddAttachment({ id: ptsEventID, type: 'Event' });
  };

  return (
    <div className={classes.attachments}>
      {!disableEdit && (
        <div className={classes.actions}>
          <Tooltip title="Add attachment">
            <span>
              <IconButton
                color="primary"
                size="small"
                component="span"
                onClick={onAddAttachment}
                disabled={!Create}>
                <AddIcon />
              </IconButton>
            </span>
          </Tooltip>
        </div>
      )}
      {!!attachments &&
        attachments.map((attachment) => (
          <Attachment
            key={attachment.ptsAttachmentID}
            attachment={attachment}
            downloadAttachment={handleDownloadAttachment}
            deleteAttachment={deleteAttachment}
            disableEdit={disableEdit}
          />
        ))}
    </div>
  );
}

const mapStateToProps = (state) => ({
  dataUpdate: state.dataUpdate,
});

export default connect(mapStateToProps, {
  showAddAttachment,
  showSpinner,
  hideSpinner,
  handleError,
  notify,
  notifyDataUpdate,
})(AttachmentsTab);
