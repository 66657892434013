import { logOut } from './UserReducer';
import { SNACKBAR_ENQUEUE } from './NotifierReducer';
import * as Sentry from '@sentry/react';

const getKey = () => new Date().getTime() + Math.random();

const getNotificationOptions = (msgType = 'error') => {
  return {
    key: new Date().getTime() + Math.random(),
    variant: msgType,
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
  };
};

const getMessage = (message, msgType) => {
  return { message, options: getNotificationOptions(msgType) };
};

const getSnackbar = (notification) => {
  return {
    type: SNACKBAR_ENQUEUE,
    notification,
    key: getKey(),
  };
};

let lastTime = 0;
let suppressErrors = false;
let errTimeout = 0;

const suppressErr = () => {
  clearTimeout(errTimeout);
  suppressErrors = true;
  errTimeout = setTimeout(() => {
    suppressErrors = false;
  }, 2000);
};

const suppressErrClasses = ['Timeout', 'timeout', 'not-authenticated'];
const suppressErrStrings = ['deadlock'];

const isSuppressError = (err) => {
  if (suppressErrors || suppressErrClasses.indexOf(err?.className) !== -1) return true;
  suppressErrStrings.forEach((msg) => {
    if (err?.message && err.message.toLowerCase().indexOf(msg.toLowerCase()) !== -1) {
      return true;
    }
  });
  return false;
};

export const handleError = (error, message) => (dispatch) => {
  let showMsg = true;
  if (isSuppressError(error)) {
    suppressErr();
    showMsg = false;
  } else {
    console.log('error: ', error);
    showMsg && Sentry.captureException(error);
  }
  const time = new Date().getTime();
  if (error && error.code === 401) {
    if (
      error.message &&
      error.message.toLowerCase().indexOf('could not connect (sequence)') !== -1
    ) {
      const notification = getMessage('Database connection error.');
      showMsg && dispatch(getSnackbar(notification));
    } else {
      const notification = getMessage("You've been logged out");
      showMsg && dispatch(getSnackbar(notification));
      dispatch(logOut());
    }
    suppressErr();
  } else if (error && error.code === 408 && time - lastTime > 5000) {
    const notification = getMessage('Network error, please check your connection.');
    showMsg && dispatch(getSnackbar(notification));
    lastTime = time;
  }
  if (!message && error.message) {
    message = error.message;
  }

  if (message) {
    if (error?.errors && error?.errors.length) {
      const { errors } = error;
      if (errors.reduce((r, v) => (typeof v !== 'string' ? false : r), true)) {
        message += ': ' + errors.join('; ');
      } else if (errors.reduce((r, v) => (typeof v !== 'object' || !v.message ? false : r), true)) {
        message += ': ' + errors.map((e) => e.message).join('; ');
      }
    }
    const notification = getMessage(message);
    showMsg && dispatch(getSnackbar(notification));
  }
};
