import store from '../config/configureStore';
import { sortObjArr, groupAndSort } from 'utils/functions';
import { saveUserSetting } from './UserSettingsReducer';

const SET_EV_PINS = 'SET_EV_PINS';
const SET_EV_GROUP_BY = 'SET_EVENT_GROUP_BY';
const SET_EV_SORT_BY = 'SET_EV_SORT_BY';
const SET_EV_ASC_DESC = 'SET_EV_ASC_DESC';
const SET_EV_FILTER = 'SET_EV_FILTER';
const SET_EV_SHOW_PINNED = 'SET_EV_SHOW_PINNED';
const SET_EV_AGENCY_FILTER = 'SET_EV_AGENCY_FILTER';
const SET_EV_STATE = 'SET_EV_STATE';
const SET_LAST_TAB = 'SET_LAST_TAB';
export const SET_EV_SORT_OPTS = 'SET_EV_SORT_OPTS';

const defaultSort = {
  pins: [], // { idx: 0, ptsEventID: 7943 }
  state: {},
  folded: [], // shows which events are unfolded and open tab [{ ptsEventID: 47889, tab: 'units' }]
  filter: '',
  lastTab: 'Units',
};

export const saveEventSort = (sortObj) => (dispatch) => {
  const loaded = store.store.getState().userSettings.loaded;
  if (!loaded) return;
  const eventSort = store.store.getState().eventSort;
  const savedObj = { ...eventSort, ...sortObj, filter: '' };
  dispatch(saveUserSetting('eventSort', savedObj));
};

export const toggleEventAgencyFilter = (AgencyID) => {
  const agencyFilter = store.store.getState().eventSort.agencyFilter;
  let newAgencyFilter;
  const present = agencyFilter.indexOf(AgencyID) !== -1;
  if (present) {
    newAgencyFilter = agencyFilter.filter((id) => id !== AgencyID);
  } else {
    newAgencyFilter = [...agencyFilter];
    newAgencyFilter.push(AgencyID);
  }
  return (dispatch) => {
    dispatch({ type: SET_EV_AGENCY_FILTER, payload: newAgencyFilter });
  };
};

export const setEventPins = (eventPins) => {
  return (dispatch) => {
    dispatch({ type: SET_EV_PINS, payload: eventPins });
  };
};

export const setEventGroupBy = (groupBy) => {
  return (dispatch) => {
    dispatch({ type: SET_EV_GROUP_BY, payload: groupBy });
  };
};

export const setEventSortBy = (sortBy) => {
  return (dispatch) => {
    dispatch({ type: SET_EV_SORT_BY, payload: sortBy });
  };
};

export const setEventAscDesc = (ascDesc) => {
  return (dispatch) => {
    dispatch({ type: SET_EV_ASC_DESC, payload: ascDesc });
  };
};

export const setEventFilter = (filter) => {
  return (dispatch) => {
    dispatch({ type: SET_EV_FILTER, payload: filter });
  };
};

export const setEventShowPinned = (showPinned) => {
  return (dispatch) => {
    dispatch({ type: SET_EV_SHOW_PINNED, payload: showPinned });
  };
};

export const setExpanded = (ptsEventID, value) => (dispatch) => {
  const eventSort = store.store.getState().eventSort;
  const evState = eventSort.state[ptsEventID] || { open: false, tab: 'Units' };
  evState.open = value;
  const newState = { ...eventSort.state, [ptsEventID]: evState };
  dispatch(saveEventSort({ state: newState }));
  dispatch({ type: SET_EV_STATE, payload: newState });
};

export const setTab = (ptsEventID, tab) => (dispatch) => {
  const { eventSort } = store.store.getState();
  const evState = eventSort.state[ptsEventID] || { open: false, tab };
  evState.tab = tab;
  const newState = { ...eventSort.state, [ptsEventID]: evState };
  dispatch(saveEventSort({ state: newState }));
  dispatch({ type: SET_EV_STATE, payload: newState });
  dispatch({ type: SET_LAST_TAB, payload: tab });
};

export const clearEvent = (ptsEventID) => (dispatch) => {
  const eventSort = store.store.getState().eventSort;
  const newState = { ...eventSort.state };
  delete newState[ptsEventID];
  dispatch(saveEventSort({ state: newState }));
  dispatch({ type: SET_EV_STATE, payload: newState });
};

/** Removes references to unused event */
export const cleanEventSort = (ptsEventID) => (dispatch) => {
  const state = store.store.getState().eventSort.state;
  const newState = { ...state };
  newState[ptsEventID] && delete newState[ptsEventID];
  dispatch(saveEventSort({ state: newState }));
  dispatch({ type: SET_EV_STATE, payload: newState });
};

export const removeClosedEventSort = (events, dispatch) => {
  const state = store.store.getState().eventSort.state;
  const removedEvents = [];
  Object.keys(state).forEach((ptsEventID) => {
    if (events.findIndex((ev) => ev.ptsEventID === parseInt(ptsEventID)) === -1)
      removedEvents.push(ptsEventID);
  });
  if (removedEvents.length > 0) {
    const newState = { ...state };
    removedEvents.forEach((ptsEventID) => {
      delete newState[ptsEventID];
    });
    dispatch(saveEventSort({ state: newState }));
    dispatch({ type: SET_EV_STATE, payload: newState });
  }
};

export const getSortedEvents = () => {
  const state = store.store.getState();
  const { eventSort, events, userSettings } = state;
  const { pins } = eventSort;
  const { eventSortBy, eventSortOrder, eventGroupBy } = userSettings;
  let sortedEvents;
  if (eventGroupBy === 'none') {
    sortedEvents = sortObjArr(events, eventSortBy, eventSortOrder);
  } else {
    sortedEvents = groupAndSort(events, eventSortBy, eventGroupBy, eventSortOrder);
  }
  if (pins.length > 0) {
    const pinIds = pins.map((p) => p.ptsEventID);
    sortedEvents = sortedEvents.filter((ev) => {
      return pinIds.indexOf(ev.ptsEventID) === -1;
    });
    pins.forEach((pin) => {
      const { idx, ptsEventID } = pin;
      const event = events.find((ev) => ev.ptsEventID === ptsEventID);
      if (event) sortedEvents.splice(idx, 0, event);
    });
  }
  return sortedEvents;
};

export const togglePin = (ptsEventID) => {
  const state = store.store.getState();
  const eventPins = state.eventSort.pins;
  const events = getSortedEvents();
  const isPinned = !!eventPins.find((pin) => pin.ptsEventID === ptsEventID);
  return (dispatch) => {
    if (isPinned) {
      const removedPin = eventPins.find((s) => s.ptsEventID === ptsEventID);
      if (!removedPin) return;
      const removedIdx = removedPin.idx;
      const newSeq = eventPins
        .filter((s) => s.ptsEventID !== ptsEventID)
        .map((s) => {
          if (s.idx <= removedIdx) return s;
          return { idx: s.idx - 1, ptsEventID: s.ptsEventID };
        });
      dispatch(setEventPins(newSeq));
    } else {
      const pinned = eventPins.map((pin) => pin.ptsEventID);
      pinned.push(ptsEventID);
      let event = events.find((ev) => ev.ptsEventID === ptsEventID);
      if (!event) event = { ptsEventID };
      const newEvents = events.filter((ev) => ev.ptsEventID !== ptsEventID);
      newEvents.unshift(event);
      let i = 0;
      const newSeq = [];
      newEvents.forEach(({ ptsEventID }) => {
        if (pinned.indexOf(ptsEventID) !== -1) newSeq.push({ idx: i++, ptsEventID });
      });
      dispatch(setEventPins(newSeq));
    }
  };
};

export const saveSeq = (reorderedEvents) => {
  const state = store.store.getState();
  const eventPins = state.eventSort.pins;
  const events = state.events;
  return (dispatch) => {
    const newPins = [];
    eventPins.forEach((s) => {
      const idx = reorderedEvents.findIndex((ev) => ev.ptsEventID === s.ptsEventID);
      const removed = !events.find((ev) => ev.ptsEventID === s.ptsEventID);
      if (!removed) newPins.push({ idx, ptsEventID: s.ptsEventID });
    });
    const sortedPins = sortObjArr(newPins, 'idx', 'ASC');
    dispatch(setEventPins(sortedPins));
  };
};

export default function reducer(state = defaultSort, action) {
  switch (action.type) {
    case SET_EV_PINS:
      return { ...state, pins: action.payload };
    case SET_EV_GROUP_BY:
      return { ...state, groupBy: action.payload };
    case SET_EV_SORT_BY:
      return { ...state, sortBy: action.payload };
    case SET_EV_ASC_DESC:
      return { ...state, ascDesc: action.payload };
    case SET_EV_FILTER:
      return { ...state, filter: action.payload };
    case SET_EV_SHOW_PINNED:
      return { ...state, showPinned: action.payload };
    case SET_EV_AGENCY_FILTER:
      return { ...state, agencyFilter: action.payload };
    case SET_EV_STATE:
      return { ...state, state: action.payload };
    case SET_LAST_TAB:
      return { ...state, lastTab: action.payload };
    case SET_EV_SORT_OPTS:
      return { ...action.payload };
    default:
      return state;
  }
}
