import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import EventWrap from './EventWrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { filterArr } from 'utils/functions';
import {
  setEventPins,
  saveSeq,
  togglePin,
  getSortedEvents,
  saveEventSort,
} from 'reducers/EventSortingReducer';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import { handleError } from 'reducers/ErrorReducer';
import { setIsStatusUpdateFailed } from 'reducers/UnitStatusReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 35,
  },
  card: {
    width: '100%',
  },
  evGrid: {
    width: '100%',
    '& tr': {
      borderBottom: '1px solid #eee',
    },
    '& th': {
      textAlign: 'center',
    },
  },
  dragHandle: {
    cursor: 'move !important',
    position: 'absolute',
    display: 'flex',
    opacity: 0,
    transition: 'opacity 0.3s',
    top: 1,
    left: 3,
    right: 3,
    height: 11,
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: 3,
    zIndex: 1,
    overflow: 'hidden',
    '& > i': {
      borderTop: `1px dotted ${theme.palette.primary.main}`,
    },
  },
  eventWrap: {
    position: 'relative',
    paddingBottom: 1,
    '&:hover $dragHandle': {
      opacity: 1,
    },
  },
  dragHandleDisabled: {
    opacity: 0,
    width: 0,
    height: 0,
    overflow: 'hidden',
  },
}));

const filterCols = [
  'CallType',
  'CallTypeDescription',
  'EventID',
  'FullLocationAddress',
  'Status',
  'PlaceName',
  'LocationDescription',
];

const isDragged = () => Boolean(window.localStorage.getItem('dragNdrop'));

function EventList(props) {
  const classes = useStyles();
  const {
    unfoldSubject,
    editEvent,
    eventSort,
    saveSeq,
    togglePin,
    events,
    eventSortBy,
    eventSortOrder,
    eventGroupBy,
    eventAgencyFilter,
    eventShowPinned,
  } = props;
  const { filter, pins } = eventSort;
  const [sortedEvents, setSortedEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [selection, setSelection] = useState([]);
  const unroutedPermission = getFullPermissions('cad', 'Unrouted Events', 'any');
  const canRoute = unroutedPermission.Read;
  const draggedTimeout = useRef(0);
  const lastChangedEvent = useRef(null);
  const firstRun = useRef(true);

  useEffect(() => {
    return () => {
      clearTimeout(draggedTimeout.current);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    updateEventSortList();
    if (!firstRun.current) props.saveEventSort();
    // eslint-disable-next-line
  }, [pins, events, eventSortBy, eventSortOrder, eventGroupBy]);

  useEffect(() => {
    if (sortedEvents) updateFilteredEvents();
    firstRun.current = false;
    // eslint-disable-next-line
  }, [sortedEvents, filter, eventShowPinned, eventAgencyFilter]);

  const updateFilteredEvents = () => {
    let newEvents = filterArr(filter, sortedEvents, filterCols);
    if (eventShowPinned) {
      const pinned = pins.map((a) => a.ptsEventID);
      newEvents = newEvents.filter((ev) => pinned.indexOf(ev.ptsEventID) !== -1);
    }
    const showAll = eventAgencyFilter.indexOf('All') !== -1;
    if (!showAll) {
      newEvents = newEvents.filter((e) => {
        const evRoutings = e.EventRouting ? e.EventRouting.map((u) => u.AgencyId) : null;
        if (!evRoutings) return canRoute;
        return evRoutings.reduce(
          (res, AgencyID) => (eventAgencyFilter.indexOf(AgencyID) === -1 ? true : res),
          false
        );
      });
    } else {
      newEvents = newEvents.filter((e) => {
        const evRoutings = e.EventRouting ? e.EventRouting.map((u) => u.AgencyId) : null;
        return evRoutings ? true : canRoute;
      });
    }
    setFilteredEvents(newEvents);
  };

  const updateEventSortList = () => {
    clearTimeout(draggedTimeout.current);
    if (isDragged()) {
      draggedTimeout.current = setTimeout(() => {
        updateEventSortList();
      }, 250);
      return;
    }
    setSortedEvents(getSortedEvents());
  };

  // called during event drag and drop
  const reorderDnd = (events, res, filteredEvents) => {
    if (!events) return;
    const from = res.source.index;
    const to = res.destination.index;
    const eventIdFrom = filteredEvents[from].ptsEventID;
    const eventIdTo = filteredEvents[to].ptsEventID;
    const fromIdx = events.findIndex((u) => u.ptsEventID === eventIdFrom);
    const toIdx = events.findIndex((u) => u.ptsEventID === eventIdTo);
    events.splice(toIdx, 0, events.splice(fromIdx, 1)[0]);
    return events;
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const newData = reorderDnd(sortedEvents, result, filteredEvents);
    const newEvents = filterArr(filter, newData, filterCols);
    saveSeq(newEvents);
    setFilteredEvents(newEvents);
  };

  const onTogglePin = (ev, ptsEventID) => {
    ev.stopPropagation();
    togglePin(ptsEventID);
  };

  const selectEvent = (ev, ptsEventID) => {
    const ctrl = ev.ctrlKey;
    const shift = ev.shiftKey;
    let newSelection = [];
    if (!ctrl && !shift) {
      // only one selected
      newSelection = selection.length === 1 && selection[0] === ptsEventID ? [] : [ptsEventID];
    } else if (ctrl) {
      // multiple selected
      const inSelection = selection.find((id) => id === ptsEventID);
      if (inSelection) {
        // remove from selection
        newSelection = selection.filter((id) => id !== ptsEventID);
      } else {
        newSelection = [...selection, ptsEventID];
      }
    } else if (shift) {
      const sortedIDs = pinnedEvents.map((ev) => ev.ptsEventID);
      const idx1 = sortedIDs.findIndex((id) => selection.indexOf(id) !== -1);
      const idx2 = sortedIDs.indexOf(ptsEventID);
      const idx = Math.min(idx1, idx2);
      const len = Math.abs(idx1 - idx2) + 1;
      newSelection = sortedIDs.splice(idx, len);
    }
    setSelection(newSelection);
  };

  /* creating happy UI for event unit list */
  useEffect(() => {
    if (props.dataUpdate?.type === 'dragndrop') {
      const time = new Date().getTime();
      const { ptsEventID, unit } = props.dataUpdate.data;
      const eventList = [...filteredEvents];
      const t = performance.now();
      const events = eventList.map((item, idx) => {
        let assignedUnits = [...item.assignedUnits];
        if (item.ptsEventID === ptsEventID) {
          lastChangedEvent.current = { ptsEventID, assignedUnits: item.assignedUnits };
          const tempUnit = {
            ...unit[0],
            UnitStatus: unit[0].UnitStatus === 'INSERVICE' ? 'DISPATCH' : 'QUEUED',
            NoOfCalls: 0,
          };
          assignedUnits.push(tempUnit);
        }
        return { ...item, assignedUnits };
      });
      setFilteredEvents(events);
    }
  }, [props.dataUpdate]);

  /* rowling back the data in case of error */
  useEffect(() => {
    const t = performance.now();
    if (props.statusUpdateFailed) {
      props.notifyDataUpdate({ type: 'unit-rollback', data: lastChangedEvent.current });
      props.handleError(null, 'Something went wrong! reload the page and try again');
    }
    return () => {
      props.setIsStatusUpdateFailed(false);
    };
  }, [props.statusUpdateFailed]);

  return (
    <div className={classes.root}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {({ innerRef, droppableProps, placeholder }, snapshot) => (
            <div ref={innerRef} {...droppableProps}>
              {filteredEvents.map((ev, index) => {
                const { ptsEventID } = ev;
                const pin = pins.find((pin) => pin.ptsEventID === ptsEventID);
                return (
                  <Draggable
                    key={ptsEventID}
                    draggableId={ptsEventID + '-event-list'}
                    index={index}
                    isDragDisabled={!pin}>
                    {({ innerRef, draggableProps, dragHandleProps }, snapshot) => (
                      <div ref={innerRef} {...draggableProps}>
                        <div className={classes.eventWrap}>
                          <div
                            className={pin ? classes.dragHandle : classes.dragHandleDisabled}
                            {...dragHandleProps}>
                            <i></i>
                            <i></i>
                            <i></i>
                            <i></i>
                            <i></i>
                            <i></i>
                          </div>
                          <EventWrap
                            ev={ev}
                            key={ptsEventID}
                            unfoldSubject={unfoldSubject}
                            editEvent={editEvent}
                            togglePin={(e) => onTogglePin(e, ev.ptsEventID)}
                            pinned={pin}
                            selectEvent={selectEvent}
                            selected={selection.indexOf(ptsEventID) !== -1}
                          />
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    eventSort: state.eventSort,
    events: state.events,
    config: state.config,
    eventSortBy: state.userSettings.eventSortBy,
    eventSortOrder: state.userSettings.eventSortOrder,
    eventGroupBy: state.userSettings.eventGroupBy,
    eventAgencyFilter: state.userSettings.eventAgencyFilter,
    eventShowPinned: state.userSettings.eventShowPinned,
    dataUpdate: state.dataUpdate,
    statusUpdateFailed: state.unitStatus.statusUpdateFailed,
  };
};

export default connect(mapStateToProps, {
  setEventPins,
  saveSeq,
  togglePin,
  handleError,
  setIsStatusUpdateFailed,
  notifyDataUpdate,
  saveEventSort,
})(EventList);
