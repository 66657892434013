import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import { setUnitStatus, unitInitEvent, safetyCheck } from 'reducers/UnitStatusReducer';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
//import ModeStandbyIcon from '@mui/icons-material/ModeStandby';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import FaceIcon from '@material-ui/icons/Face';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import { newUnitStatus, editUnitStatus, setUnitZone } from 'reducers/DialogsReducer';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import EditIcon from '@material-ui/icons/Edit';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AssignmentIcon from '@material-ui/icons/Assignment';
import EventNoteIcon from '@material-ui/icons/EventNote';
import { showEditUnit } from 'reducers/DialogsReducer';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import DispatchMenu from './DispatchMenu';
import SubUnitStatusMenu from './SubUnitStatusMenu';
//import AttributionIcon from '@material-ui/icons/Attribution';
const useStyles = makeStyles(() => ({
  menu: {
    '& li': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  menuIcon: {
    margin: '0 10px 0 -10px',
  },
  menuRight: {
    position: 'relative',
    '& > div': {
      width: 10,
      height: 10,
      position: 'absolute',
      top: 0,
      right: 0,
    },
  },
}));

function getDefaultEvent(events, unit) {
  if (!unit) return null;
  const { ptsUnitID } = unit;
  let output = '';
  events.forEach((event) => {
    const result = event.assignedUnits.find((unit) => unit.ptsUnitID === ptsUnitID);
    if (result) output = event.ptsEventID;
  });
  return output;
}

function UnitStatusMenu(props) {
  const classes = useStyles();
  const { events, unit, open, onClose, anchorEl, options, ptsEventID, noOfCalls } = props;
  const { ptsUnitID } = unit;
  const { UnitActionCodes } = options;
  const [dispatchMenuOpen, setDispatchMenuOpen] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [curStatus, setCurStatus] = useState('');
  const [eventsPerUnit, setEventsPerUnit] = useState([]);
  const dispatchRef = useRef();
  const subMenuRef = useRef();

  const setUnitStatus = (status) => {
    const eventID = ptsEventID ? ptsEventID : getDefaultEvent(events, unit);
    props.setUnitStatus(status, ptsUnitID, eventID);
    onClose();
    onClose && onClose();
  };

  const newStatus = () => {
    props.newUnitStatus(ptsUnitID, ptsEventID);
    onClose && onClose();
  };

  const newEvent = () => {
    props.unitInitEvent(ptsUnitID);
    onClose && onClose();
  };

  const editStatus = () => {
    onClose && onClose();
    if (window.confirm('Are you sure you want to edit status record?')) {
      props.editUnitStatus(ptsUnitID, ptsEventID);
    }
  };

  const editUnit = () => {
    props.showEditUnit(ptsUnitID);
    onClose && onClose();
  };

  const setZone = () => {
    props.setUnitZone(ptsUnitID);
    onClose && onClose();
  };

  const onSafetyCheckClick = () => {
    safetyCheck(ptsUnitID, unit.Unit);
    onClose && onClose();
  };

  const handleEventStatusClick = (status) => {
    /* checking if unit assigned to a single event or more */

    if (eventsPerUnit.length === 1) {
      props.setUnitStatus(status, ptsUnitID, eventsPerUnit[0]);
      onClose && onClose();
    } else {
      setCurStatus(status);
      setSubMenuOpen(true);
    }
  };

  useEffect(() => {
    /* mapping number of events assigned for a unit */
    let list = [];
    for (const ev of events) {
      const { assignedUnits } = ev;
      if (assignedUnits.some((curUnit) => curUnit.ptsUnitID === ptsUnitID))
        list.push(ev.ptsEventID);
      if (list.length > 1) break;
    }
    setEventsPerUnit(list);
  }, [unit, events, ptsUnitID]);
  const IsDisabled = noOfCalls === 0;
  return (
    <>
      <Menu anchorEl={anchorEl} keepMounted open={open} onClose={onClose}>
        <MenuItem onClick={() => setUnitStatus(UnitActionCodes.Inservice.status)}>
          <FaceIcon className={classes.menuIcon} /> {UnitActionCodes.Inservice.status}
        </MenuItem>
        <MenuItem onClick={newStatus}>
          <AssignmentIcon className={classes.menuIcon} /> Change Status
        </MenuItem>
        <MenuItem onClick={onSafetyCheckClick}>
          <AccessTimeIcon className={classes.menuIcon} /> Safety Check
        </MenuItem>
        <MenuItem component={NavLink} to={`/search/unit-status?u=${unit.Unit}`}>
          <AssessmentIcon className={classes.menuIcon} /> Status History
        </MenuItem>
        <MenuItem onClick={newEvent}>
          <EventNoteIcon className={classes.menuIcon} /> Unit Initiated Event
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => setDispatchMenuOpen(true)}>
          <HeadsetMicIcon className={classes.menuIcon} /> {UnitActionCodes.Dispatch.status}
          <span style={{ marginLeft: 'auto' }}>
            {' '}
            <ArrowRightIcon ref={dispatchRef} />{' '}
          </span>
        </MenuItem>
        <MenuItem
          onClick={() => handleEventStatusClick(UnitActionCodes.Enroute.status)}
          disabled={IsDisabled}>
          <DriveEtaIcon className={classes.menuIcon} /> {UnitActionCodes.Enroute.status}
          {eventsPerUnit.length !== 1 && (
            <span style={{ marginLeft: 'auto' }}>
              {' '}
              <ArrowRightIcon ref={subMenuRef} />{' '}
            </span>
          )}
        </MenuItem>
        <MenuItem
          onClick={() => handleEventStatusClick(UnitActionCodes.Arrived.status)}
          disabled={IsDisabled}>
          <EmojiTransportationIcon className={classes.menuIcon} /> {UnitActionCodes.Arrived.status}
          {eventsPerUnit.length !== 1 && (
            <span style={{ marginLeft: 'auto' }}>
              {' '}
              <ArrowRightIcon ref={subMenuRef} />{' '}
            </span>
          )}
        </MenuItem>
        <MenuItem
          onClick={() => handleEventStatusClick(UnitActionCodes.Completed.status)}
          disabled={IsDisabled}>
          <AssignmentTurnedInIcon className={classes.menuIcon} />
          {UnitActionCodes.Completed.status}
          {eventsPerUnit.length !== 1 && (
            <span style={{ marginLeft: 'auto' }}>
              {' '}
              <ArrowRightIcon ref={subMenuRef} />{' '}
            </span>
          )}
        </MenuItem>
        <Divider />
        <MenuItem onClick={editStatus}>
          <EditIcon className={classes.menuIcon} /> Edit Status
        </MenuItem>
        <MenuItem onClick={setZone}>
          <LocationOnIcon className={classes.menuIcon} /> Edit Zone
        </MenuItem>
        <MenuItem onClick={editUnit}>
          <EditIcon className={classes.menuIcon} /> Edit Unit
        </MenuItem>
        <MenuItem onClick={() => setUnitStatus(UnitActionCodes.Outservice.status)}>
          <NotificationsOffIcon className={classes.menuIcon} /> {UnitActionCodes.Outservice.status}
        </MenuItem>
        <MenuItem onClick={() => setUnitStatus(UnitActionCodes.Standby.status)}>
          <FaceIcon className={classes.menuIcon} /> {UnitActionCodes.Standby.status}
        </MenuItem>
      </Menu>
      <DispatchMenu
        anchorEl={dispatchRef.current}
        open={dispatchMenuOpen}
        onClose={() => setDispatchMenuOpen(false)}
        units={[unit]}
      />
      <SubUnitStatusMenu
        anchorEl={subMenuRef.current}
        open={subMenuOpen}
        onClose={() => setSubMenuOpen(false)}
        unit={unit}
        status={curStatus}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    events: state.events,
    options: state.config.options,
  };
};

export default connect(mapStateToProps, {
  setUnitStatus,
  newUnitStatus,
  editUnitStatus,
  setUnitZone,
  unitInitEvent,
  showEditUnit,
})(UnitStatusMenu);
